<template>
    <div>
        <div class="repertory_back">
           <van-nav-bar title="我的库存">
                <template #left>
                    <van-button class="repertory_backBut" @click="repertoryBack" size="normal" :icon="left" type="default">
                        返回
                    </van-button>
                </template>
            </van-nav-bar>
        </div>
        <div class="repertory_info">
            <div v-if="tableData.length==0" class="repertory_empty">
                没有库存数据...!
            </div>
            <div v-if="tableData.length>0">
                <van-list>
                    <van-cell v-for="(item,index) in tableData" :key="index">
                        <van-row class="repertory_row">
                            <van-col span="2">{{index+1}}</van-col>
                            <van-col span="20">{{item.code}},{{item.name}}</van-col>
                            <van-col span="2">{{item.amount}}</van-col>
                        </van-row>
                    </van-cell>
                </van-list>
            </div>
        </div>
    </div>
</template>
<script>
import {inventoryInitData} from '../api/index.js';
import {Toast} from 'vant';
export default {
    data() {
        return {
            left:require('../assets/left.png'),
            tableData:[],
        }
    },
    created() {
        let toast = Toast.loading({message: '库存数据加载中...',forbidClick: true,duration:0,overlay:true});
        inventoryInitData().then((res) =>{
            this.tableData=res.sqlData;
            toast.clear();
        })
    },
    mounted() {
        
    },
    methods: {
      repertoryBack(){
          this.$router.push('/');
      },  
    },
}
</script>
<style scoped>
.repertory_row{
    color: rgb(128, 128, 128);
    text-align: center;
    font-size: 14px;
}
.repertory_back{
    background-color: white;
}
.repertory_backBut{
    padding-left: 0px;
    padding-right: 0px;
    border: none;
}
.repertory_info{
    margin-top: 5px;
    border: 1px solid rgb(238, 238, 238);
    height: 92vh;
    overflow:auto;
}
.repertory_empty{
    border: 1px solid rgb(240, 240, 240);
    background-color: white;
    border-radius: 10px;
    margin: 10px;
    line-height: 30vh;
    color: darkgray;
    text-align: center;
}
</style>