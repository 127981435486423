<template>
  <div>
    <div style="height:10px;"></div>
    <div class="home_body">
      <div style="height:10px;"></div>
      <div class="home_f_title">
        <span class="home_span1"></span>订单管理
      </div>
      <van-row>
        <van-col span="12">
          <div class="home_f_div" @click="startFunction('PlaceAnOrder')">
            <div class="home_f_divtext1">
              我要下单
            </div>
            <div class="home_f_divtext2">
              我要下单
            </div>
            <div class="home_f_divico">
              <van-icon :name="order" size="18"/>
            </div>
          </div>
        </van-col>
        <van-col span="12">
          <div class="home_f_div" @click="startFunction('OrderInquiry')">
            <div class="home_f_divtext1">
              订单查询
            </div>
            <div class="home_f_divtext2">
              订单查询
            </div>
            <div class="home_f_divico">
              <van-icon :name="inquire" size="18"/>
            </div>
          </div>
        </van-col>
        <van-col span="12">
          <div class="home_f_div" @click="startFunction('Inform')">
            <div class="home_f_divtext1">
              发货信息通知
            </div>
            <div class="home_f_divtext2">
              发货信息通知
            </div>
            <div class="home_f_divico">
              <van-icon :name="inform" size="18"/>
            </div>
          </div>
        </van-col>
        <van-col span="12">
          <div class="home_f_div" @click="startFunction('OrderToModify')">
            <div class="home_f_divtext1">
              订单修改
            </div>
            <div class="home_f_divtext2">
              订单修改
            </div>
            <div class="home_f_divico">
              <van-icon :name="edit" size="18"/>
            </div>
          </div>
        </van-col>
      </van-row>
      <div style="height:10px;"></div>
      <div class="home_f_title">
        <span class="home_span2"></span>货物管理
      </div>
      <van-row>
        <van-col span="12">
          <div class="home_f_div" @click="startFunction('ShipmentScan')">
            <div class="home_f_divtext1">
              发货扫描
            </div>
            <div class="home_f_divtext2">
              发货扫描
            </div>
            <div class="home_f_divico">
              <van-icon :name="deliver" size="18"/>
            </div>
          </div>
        </van-col>
        <van-col span="12">
          <div class="home_f_div" @click="startFunction('ReceivingScan')">
            <div class="home_f_divtext1">
              收货扫描
            </div>
            <div class="home_f_divtext2">
              收货扫描
            </div>
            <div class="home_f_divico">
              <van-icon :name="take" size="18"/>
            </div>
          </div>
        </van-col>
        <van-col span="12">
          <div class="home_f_div" @click="startFunction('Repertory')">
            <div class="home_f_divtext1">
              我的库存
            </div>
            <div class="home_f_divtext2">
              我的库存
            </div>
            <div class="home_f_divico">
              <van-icon :name="repertory" size="18"/>
            </div>
          </div>
        </van-col>
      </van-row>
      <div style="height:10px;"></div>
      <div class="home_f_title">
        <span class="home_span3"></span>个人中心
      </div>
      <van-row>
        <van-col span="12">
          <div class="home_f_div" @click="startFunction('MyProfile')">
            <div class="home_f_divtext1">
              我的资料
            </div>
            <div class="home_f_divtext2">
              我的资料
            </div>
            <div class="home_f_divico">
              <van-icon :name="personal" size="18"/>
            </div>
          </div>
        </van-col>
      </van-row>
    </div>
  </div>
</template>
<script>
import {clientLogin} from '../api/index';
import {Dialog, Toast} from 'vant';

export default {
  data() {
    return {
      deliver: require('../assets/deliver.png'), // 发货
      edit: require('../assets/edit.png'),  //修改订单
      inform: require('../assets/inform.png'), // 收货通知
      inquire: require('../assets/inquire.png'),  //查询
      order: require('../assets/order.png'),  //下单
      repertory: require('../assets/repertory.png'), // 库存
      take: require('../assets/take.png'),  //收货
      personal: require('../assets/personal.png'),  //个人中心
    }
  },
  created() {
    this.code = this.getUrlCode().code;
    if (window.sessionStorage.getItem('userInfo') == null) {
      let toast = Toast.loading({message: '信息加载中...', forbidClick: true, duration: 0, overlay: true});
      clientLogin('code=' + this.code).then(res => {
        console.log(res);
        if (res.code === 200) {
          window.sessionStorage.setItem("userInfo", res.data);
          window.sessionStorage.setItem("ydGzhToken", res.ydGzhToken);
          console.log(window.sessionStorage);
        }
        toast.clear();
      })
    } else {
      console.log("已授权过")
      console.log(window.sessionStorage);
    }
  },
  mounted() {
  },
  methods: {
    startFunction(name) {
      let token = window.sessionStorage.getItem("ydGzhToken");
      console.log(token)
      if (token == null) {
        Dialog.confirm({
          title: '系统提示',
          message: '没有认证的账号，是否去认证...!',
        }).then(() => {
          this.$router.push('/Apply');
        }).catch(() => {
        });
        return false;
      }
      switch (name) {
        case 'PlaceAnOrder'://我要下单
          this.$router.push('/PlaceAnOrder');
          break;
        case 'OrderInquiry'://订单查询
          this.$router.push('/OrderInquiry');
          break;
        case 'Inform'://发货信息通知
          Dialog.alert({
            title: '系统提示',
            message: '未开放的功能...!',
          }).then(() => {
          });
          // this.$router.push('/Inform');
          break;
        case 'OrderToModify'://订单修改
          this.$router.push('/OrderToModify');
          break;
        case 'ShipmentScan'://发货扫描
          this.$router.push('/ShipmentScan');
          break;
        case 'ReceivingScan'://收货扫描
          this.$router.push('/ReceivingScan');
          break;
        case 'Repertory'://库存查询
          this.$router.push('/Repertory');
          break;
        case 'MyProfile'://我的资料
          this.$router.push('/MyProfile');
          break;
      }
    },
    getUrlCode() { // 截取url中的code方法
      var url = location.search
      this.winUrl = url
      var theRequest = new Object()
      if (url.indexOf("?") != -1) {
        var str = url.substr(1)
        var strs = str.split("&")
        for (var i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = (strs[i].split("=")[1])
        }
      }
      return theRequest
    },
  },
}
</script>
<style scoped>
.home_f_divtext1 {
  font-weight: bold;
  font-size: 1rem;
}

.home_f_divtext2 {
  color: darkgray;
  font-size: 0.8rem;
}

.home_f_div {
  border: 1px solid #b3b3b3;
  background: white;
  border-radius: 5px;
  box-shadow: 3px 3px 8px #cecece;
  margin: 10px;
  padding: 8px;
}

.home_span1 {
  border: 3px solid rgb(58, 58, 255);
  margin-right: 5px;
}

.home_span2 {
  border: 3px solid rgb(214, 211, 15);
  margin-right: 5px;
}

.home_span3 {
  border: 3px solid rgb(255, 44, 150);
  margin-right: 5px;
}

.home_body {
  padding: 0.5rem;
}

.home_f_title {
  color: rgb(126, 126, 126);
  padding-left: 10px;
}

.home_f_divico {
  text-align: right;
}
</style>