<template>
  <div>
    <van-nav-bar title="后台管理">
      <template #left>
        <van-icon name="wap-home-o" size="24" color="#928FFF" @click="applyBack" />
      </template>
    </van-nav-bar>

    <div class="home_body">
      <div class="home_title">
        <van-icon name="arrow" color="#928FFF" />
        <span>客户管理</span>
      </div>
      <van-grid :column-num="3" :gutter="15">
        <van-grid-item icon="friends-o" text="所有客户" to="/userList" />
        <van-grid-item icon="certificate" text="资料审核" to="/userCheckList" />
<!--        <van-grid-item icon="star-o" text="测试" url="javascript:alert('功能正在建设中……')" />-->
      </van-grid>
      <div class="home_title">
        <van-icon name="arrow" color="#928FFF" />
        <span>发货管理</span>
      </div>
      <van-grid :column-num="3" :gutter="15">
        <van-grid-item icon="scan" text="扫描记录" to="/scanList" />
        <van-grid-item icon="down" text="记录下载" to="/scanDownload" />
        <van-grid-item icon="star-o" text="产品类型" url="javascript:alert('功能正在建设中……')" />
      </van-grid>
<!--      <van-row gutter="15">
        <van-col span="8">
          <div class="home_div" @click="startFunction('userList')">
            <van-icon name="friends-o" size="28" color="#928FFF" />
            <div class="home_div_text">
              <span>所有客户</span>
            </div>
          </div>
        </van-col>
        <van-col span="8">
          <div class="home_div" @click="startFunction('userCheckList')">
            <van-icon name="certificate" size="28" color="#928FFF" />
            <div class="home_div_text">
              <span>资料审核</span>
            </div>
          </div>
        </van-col>
        <van-col span="8">
          <div class="home_div" @click="startFunction('scanRecords')">
            <van-icon name="scan" size="28" color="#928FFF" />
            <div class="home_div_text">
              <span>扫描记录</span>
            </div>
          </div>
        </van-col>
      </van-row>-->
    </div>
  </div>
</template>

<script>
import {Dialog} from "vant";

export default {
  name: "Index",
  created() {
    history.pushState(null, null, document.URL);
    window.addEventListener('popstate', function () {
      history.pushState(null, null, document.URL);
    });
  },
  mounted() {

  },
  methods: {
    applyBack() {
      let url = location.href.split('#')[0] + '#/'
      window.location.assign(url);
    },
    startFunction(name) {
      switch (name) {
        case 'userList'://所有客户
          this.$router.push('/userList');
          break;
        case 'userCheckList'://资料审核
          this.$router.push('/userCheckList');
          break;
        case 'scanRecords'://扫描记录
          Dialog.alert({
            title: '系统提示',
            message: '功能正在建设中！',
            confirmButtonColor: '#928FFF'
          }).then(() => {
          });
          // this.$router.push('/userCheckList');
          break;
      }
    },
  }
}
</script>

<style scoped>
.home_body {
  /*margin-left: 2rem;*/
  /*margin-right: 2rem;*/
}

.home_title {
  color: rgb(126, 126, 126);
  padding: 15px;
  /** 文本 */
  font-size: 1rem;
  font-weight: 300;
  text-align: left;
  vertical-align: top;
  /* 叠加 */
  display: flex;
  /* 子级元素垂直居中 */
  align-items: center;
}

.home_div {
  border: 1px solid rgb(224, 224, 224);
  background: white;
  border-radius: 5px;
  box-shadow: 3px 3px 8px #cecece;
  padding: 10px;
  text-align: center;
}

.home_div_text {
  color: darkgray;
  font-size: 0.8rem;
}

.van-grid {
  color: #928FFF;
}

/deep/ .van-grid-item__content--surround::after {
  border: none;
}

/deep/ .van-grid-item__content {
  border: 1px solid rgb(224, 224, 224);
  border-radius: 10px;
  box-shadow: 3px 3px 8px #cecece;
}

/deep/ .van-grid-item__text {
  color: #A9A9A9;
}

</style>