<template>
  <div class="gzh_app" :style="'min-height: '+ClientHeight+'px;'">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "Index",
  data() {
    return {
      ClientHeight: document.documentElement.clientHeight,
    }
  },
}
</script>

<style scoped>
.gzh_app{
  background-color: rgb(248, 248, 248);
}
</style>