// 1. vue package.json 引入crypto-js的依赖："crypto-js": "^4.0.0",
// npm install crypto-js --force
// 导入
import CryptoJS from 'crypto-js';
// 这两个必须是16位前后端一致
const key = CryptoJS.enc.Utf8.parse("yudehb10yudehb10");
// 加密
const encrypt = (text) => {
    let srcs = CryptoJS.enc.Utf8.parse(text);
    let encrypted = CryptoJS.AES.encrypt(srcs, key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
};
// 解密
const decrypt = (text) => {
    let decrypt = CryptoJS.AES.decrypt(text, key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });
    return CryptoJS.enc.Utf8.stringify(decrypt).toString();
};

export {
    encrypt, decrypt
};
