<template>
  <div class="apply_body">
    <div class="apply_top">
      <div class="apply_left">
        <van-icon name="arrow-left" size="24" color="#928FFF" @click="applyBack" />
      </div>
      <van-search clearable placeholder="请输入客户姓名 / 公司名称"
                  v-model="inputValue" @input="handleSearch(false)" />
    </div>
    <div class="apply_info">
      <van-empty description="暂无待审核客户" v-if="users.length === 0" />
      <van-cell-group v-if="users.length !== 0" >
        <van-cell v-for="item in usersSearch"
                  :key="item['id']"
                  :title="item['name']"
                  :label="item['company']"
                  :value="parseTimeStr(item['createtime'])"
                  @click="handleSkip(item['id'])"
        />
      </van-cell-group>
    </div>

  </div>
</template>

<script>
import {listUserCheck} from "@/api";
import {parseTime} from "@/api/common";

export default {
  name: "UserCheckList",
  data() {
    return {
      code: '',
      users: [],
      usersSearch: [],
      inputValue: ''
    }
  },
  created() {
    // history.pushState(null, null, document.URL);
    // window.addEventListener('popstate', function () {
    //   history.pushState(null, null, document.URL);
    // });
  },
  mounted() {
    listUserCheck().then(res => {
      this.users = res.data;
      this.usersSearch = this.users;
    });
  },
  methods: {
    applyBack() {
      this.$router.push('/manageIndex');
    },
    parseTimeStr(time, pattern) {
      return parseTime(time, pattern);
    },
    handleSearch() {
      let searchResult = this.users.filter((sr) => {
        return sr.name.indexOf(this.inputValue) !== -1;
      });
      if (searchResult.length === 0) {
        searchResult = this.users.filter((sr) => {
          return sr.company.indexOf(this.inputValue) !== -1;
        });
      }
      this.usersSearch = searchResult;
    },
    handleSkip(id) {
      this.users.forEach((item) => {
        if (item['id'] === id) {
          window.sessionStorage.setItem('userCheck', JSON.stringify(item));
        }
      });
      /* 跳转 */
      this.$router.push({
        name: 'UserCheck',
        params: {
          userId: id
        }
      });
      // let url = location.href.split('#')[0] + '#/userCheck?userId=' + id
      // window.location.assign(url);
    }
  },
}
</script>

<style scoped>
.apply_body {
  margin-left: 10px;
  margin-right: 10px;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.apply_top {
  background-color: rgb(248,248,248);
  padding-top: 15px;
  padding-bottom: 15px;
}

.apply_left {
  padding-right: 10px;
  float: left;
}

.van-search {
  padding: 0;
  height: 24px;
}

.van-search__content {
  background-color: white;
}

.apply_info {
  flex: 1;
  overflow-y: scroll;
}

.van-cell__title {
  min-width: 50%;
}

.van-cell__label {
  width: 250px;
}

.van-cell__value {
  min-width: 50%;
}

</style>