<template>
    <div>
        <div class="myProfile_back">
            <van-nav-bar title="我的资料">
                <template #left>
                    <van-button class="myProfile_backBut" @click="myProfileBack" size="normal" :icon="left" type="default">
                        返回
                    </van-button>
                </template>
            </van-nav-bar>
        </div>
        <div style="height:10px;"></div>
        <div class="myProfile_head">
            <div style="height:10px;"></div>
            <van-row>
                <van-col span="8">
                    <div class="myProfile_userInfo_imgDiv">
                        <img :src="headimgurl" class="myProfile_user_imgurl"/>
                    </div>
                </van-col>
                <van-col span="16">
                    <div style="height: 4rem;">
                        <div style="height:0.8rem;"></div>
                        <div class="myProfile_userInfo_nickname1">
                            {{company}}
                        </div>
                        <div class="myProfile_userInfo_nickname2">
                            {{nickname}}
                        </div>
                    </div>
                </van-col>
            </van-row>
            <div style="height:10px;"></div>
        </div>
        <div class="myProfile_infoDiv">
            <van-list>
                <van-cell>
                    <div @click="editInfo('company')">
                        <van-row>
                            <van-col span="2"><van-icon size="26" :name="companyico"/></van-col>
                            <van-col span="22" class="myProfile_infoDiv_item">公司名称</van-col>
                        </van-row>
                    </div>
                </van-cell>
                <van-cell>
                    <div @click="editInfo('name')">
                        <van-row>
                            <van-col span="2"><van-icon size="26" :name="name"/></van-col>
                            <van-col span="22" class="myProfile_infoDiv_item">真实姓名</van-col>
                        </van-row>
                    </div>
                </van-cell>
                <van-cell>
                    <div @click="editInfo('tel')">
                        <van-row>
                            <van-col span="2"><van-icon size="26" :name="tel"/></van-col>
                            <van-col span="22" class="myProfile_infoDiv_item">电话</van-col>
                        </van-row>
                    </div>
                </van-cell>
                <van-cell>
                    <div @click="myAddress">
                        <van-row>
                            <van-col span="2"><van-icon size="26" :name="address"/></van-col>
                            <van-col span="22" class="myProfile_infoDiv_item">收货地址</van-col>
                        </van-row>
                    </div>
                </van-cell>
            </van-list>
            <div style="height:15rem;"></div>
        </div>
        <van-dialog v-model="myProfileDialog" :title="dialogInfo.title" @confirm="dialogConfirm" @cancel="dialogCancel" show-cancel-button>
            <van-cell-group>
                <van-field input-align="center" v-model.trim="dialogInfo.val"/>
            </van-cell-group>
        </van-dialog>
    </div>
</template>
<script>
import { clientEdit } from '../api/index.js';
import { Dialog } from 'vant';
export default {
    data() {
        return {
            left:require('../assets/left.png'), //返回 
            companyico:require('../assets/company.png'),
            tel:require('../assets/tel.png'),
            name:require('../assets/name.png'),
            address:require('../assets/address.png'),
            headimgurl:'',
            nickname:'',
            company:'',
            dialogInfo:{},
            myProfileDialog:false,
        }
    },
    created() {
        let user=JSON.parse(window.sessionStorage.getItem("userInfo"));
        this.company=user.company;
        this.headimgurl=user.headimgurl;
        this.nickname=user.nickname;
    },
    mounted() { 
    },
    methods: {
        dialogConfirm(){
            let user=JSON.parse(window.sessionStorage.getItem("userInfo"));
            let tf=true;
            switch(this.dialogInfo.property){
                case 'company':
                    tf=this.dialogInfo.val==user.company
                    break;
                case 'name':
                    tf=this.dialogInfo.val==user.name
                    break;
                case 'tel':
                    tf=this.dialogInfo.val==user.tel
                    break;
            }
            if(tf){
                return false;
            }
            clientEdit(this.dialogInfo).then(res =>{
                if(res.result==1){
                    user[this.dialogInfo.property]=this.dialogInfo.val;
                    this.company=user.company;
                    this.headimgurl=user.headimgurl;
                    this.nickname=user.nickname;
                    window.sessionStorage.setItem("userInfo",JSON.stringify(user));
                }else{
                    Dialog.alert({
                        title: '系统提示',
                        message: res.msg,
                    }).then(() => {
                    });
                }
            })
        },
        dialogCancel(){
        },
        myAddress(){
            this.$router.push('/MyAddress');
        },
        editInfo(e){
            let user=JSON.parse(window.sessionStorage.getItem("userInfo"));
            switch(e){
                case 'company':
                    this.dialogInfo={title:'公司名称',property:'company',val:user.company};
                    break;
                case 'name':
                    this.dialogInfo={title:'真实姓名',property:'name',val:user.name};
                    break;
                case 'tel':
                    this.dialogInfo={title:'我的电话',property:'tel',val:user.tel};
                    break;
            }
            this.myProfileDialog=true;
        },
        myProfileBack(){
            this.$router.push('/');
        },
    },
}
</script>
<style scoped>
.myProfile_infoDiv_item{
    font-size: 1rem;
    line-height: 28px;
}
.myProfile_infoDiv{
    margin: 0.5rem;
    border: 1px solid rgb(245, 245, 245);
    border-radius: 0.5rem;
    background-color: white;
    padding: 0.3rem 0rem;
}
.myProfile_back{
    background-color: white;
}
.myProfile_backBut{
    padding-left: 0px;
    padding-right: 0px;
    border: none;
}
.myProfile_head{
    background-color: white;
}
.myProfile_userInfo_imgDiv{
    width: 4rem;
    height: 4rem;
    margin: auto;
}
.myProfile_user_imgurl{
    width: 100%;
    height: 100%;
    border-radius: 2rem;
}
.myProfile_userInfo_nickname1{
    font-size: 1.2rem;
    font-weight: bold;
}
.myProfile_userInfo_nickname2{
    font-size: 0.9rem;
    color: darkgray;
}
</style>