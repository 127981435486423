import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

import Index from '@/pages/lyn/Index.vue';
import Login from '@/pages/lyn/Login.vue';
import Home from '@/pages/lyn/Home.vue';
import MyInfo from '@/pages/lyn/MyInfo.vue';
import ScanResult from '@/pages/lyn/ScanResult.vue';
import MyScanList from '@/pages/lyn/MyScanList.vue';

import ManageIndex from '@/pages/lyn/manage/Index.vue';
import UserList from '@/pages/lyn/manage/UserList.vue';
import UserInfo from '@/pages/lyn/manage/UserInfo.vue';
import UserCheckList from '@/pages/lyn/manage/UserCheckList.vue';
import UserCheck from '@/pages/lyn/manage/UserCheck.vue';
import ScanList from '@/pages/lyn/manage/ScanList.vue';
import ScanInfo from '@/pages/lyn/manage/ScanInfo.vue';
import ScanDownload from '@/pages/lyn/manage/ScanDownload.vue';

import H from '../pages/Home.vue';
import Apply from '../pages/Apply.vue';
import PlaceAnOrder from '../pages/PlaceAnOrder.vue';//我要下单
import OrderInquiry from '../pages/OrderInquiry.vue';//订单查询
import OrderDetails from '../pages/OrderDetails.vue';//订单详情
import Inform from '../pages/Inform.vue';//通知
import OrderToModify from '../pages/OrderToModify.vue';//订单修改
import OrderToEdit from '../pages/OrderToEdit.vue';//订单修改详情
import ShipmentScan from '../pages/ShipmentScan.vue';//发货扫描
import ReceivingScan from '../pages/ReceivingScan.vue'; //收货扫描
import Repertory from '../pages/Repertory.vue';//库存查询
import MyProfile from '../pages/MyProfile.vue';//我的资料
import MyAddress from '../pages/MyAddress.vue';//我的收货地址
import AddAddress from '../pages/AddAddress.vue';//新增收货地址

export default new Router({
    mode: 'hash',
    /*scrollBehavior: () => {
        history.pushState(null, null, document.URL);
    },*/
    routes: [
        { path: "/index", name: "Index", component: Index },
        { path: "/login", name: "Login", component: Login },
        { path: "/", name: "Home", component: Home },
        { path: "/myInfo", name: "MyInfo", component: MyInfo },
        { path: "/scanResult", name: "ScanResult", component: ScanResult },
        { path: "/myScanList", name: "MyScanList", component: MyScanList },

        { path: "/manageIndex", name: "ManageIndex", component: ManageIndex },
        { path: "/userList", name: "UserList", component: UserList },
        { path: "/userInfo", name: "UserInfo", component: UserInfo },
        { path: "/userCheckList", name: "UserCheckList", component: UserCheckList },
        { path: "/userCheck", name: "UserCheck", component: UserCheck },
        { path: "/scanList", name: "ScanList", component: ScanList },
        { path: "/scanInfo", name: "ScanInfo", component: ScanInfo },
        { path: "/scanDownload", name: "ScanDownload", component: ScanDownload },

        { path: "/Home", name: "H", component: H },
        { path: "/Apply", name: "Apply", component: Apply },
        { path: "/PlaceAnOrder", name: "PlaceAnOrder", component: PlaceAnOrder },
        { path: "/OrderInquiry", name: "OrderInquiry", component: OrderInquiry },
        { path: "/OrderDetails", name: "OrderDetails", component: OrderDetails },
        { path: "/Inform", name: "Inform", component: Inform },
        { path: "/OrderToModify", name: "OrderToModify", component: OrderToModify }, 
        { path: "/OrderToEdit", name: "OrderToEdit", component: OrderToEdit },
        { path: "/ShipmentScan", name: "ShipmentScan", component: ShipmentScan },
        { path: "/ReceivingScan", name: "ReceivingScan", component: ReceivingScan },
        { path: "/Repertory", name: "Repertory", component: Repertory },
        { path: "/MyProfile", name: "MyProfile", component: MyProfile },
        { path: "/MyAddress", name: "MyAddress", component: MyAddress },
        { path: "/AddAddress", name: "AddAddress", component: AddAddress },
    ]
})