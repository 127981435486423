<template>
  <div class="div-body">
    <van-row>
      <van-col span="20">
        <div class="div-title">
          <span>发货扫描</span>
        </div>
        <div class="div-description">
          <span>发货扫描日期为三包凭证</span>
        </div>
      </van-col>
    </van-row>
<!--    <div class="div-scan">
      <div class="div-base">
        <div class="div-circle"/>
        <div class="div-circle"/>
        <div class="div-circle"/>
        <div class="div-circle"/>
        <div class="div-scanButton">
          <img :src="scanButton" />
        </div>
      </div>
    </div>-->
  </div>
</template>

<script>
import {wxLogin, sendLogin} from "@/api";
import {Dialog, Toast} from "vant";
import {getUrlCode} from "@/api/common";

export default {
  name: "Login",
  data() {
    return {
      toast: undefined,
      scanButton: require('@/assets/scanshipment/ScanButton.svg'),
    }
  },
  created() {
    this.toast = Toast.loading({message: '信息加载中...', forbidClick: true, duration: 0, overlay: true});
    let userSession = window.localStorage.getItem('userSession');
    if (userSession !== null) {
      let userJson = JSON.parse(userSession);
      let openid = userJson['openid'];
      let nickname = userJson['nickname'];
      sendLogin('openid=' + openid + '&nickname=' + nickname).then(res => {
        if (res.code === 200) {
          let data = res.data;
          if (data === 43004) {
            let url = 'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=' + 'MzkxODI3MTg2NQ==' + '&scene=110#wechat_redirect';
            window.location.assign(url);
          } else {
            window.localStorage.setItem('userInfo', userSession);
            // this.$router.push('/');
            let url = location.href.split('#')[0] + '#/';
            window.location.replace(url);
          }
        } else {
          Dialog.alert({
            title: '系统提示',
            message: '登录错误',
            confirmButtonColor: '#928FFF',
            closeOnPopstate: false
          }).then(() => {
            this.onCancel();
          }).catch(() => {
          });
        }
      });
    }
  },
  mounted() {
    let code = getUrlCode().code;
    let userSession = window.localStorage.getItem('userSession');
    if (userSession == null) {
      wxLogin('code=' + code).then(res => {
        if (res.code === 200) {
          window.localStorage.setItem('userInfo', JSON.stringify(res.data));
          window.localStorage.setItem('userSession', JSON.stringify(res.data));
          // this.$router.push('/');
          let url = location.href.split('#')[0] + '#/';
          window.location.replace(url);
        } else {
          let data = res.data;
          Dialog.alert({
            title: '系统提示',
            message: res.msg,
            confirmButtonColor: '#928FFF',
            closeOnPopstate: false
          }).then(() => {
            if (data === -1) {
              window.localStorage.clear();
              this.onCancel();
            } else {
              window.localStorage.setItem('userSession', JSON.stringify(res.data));
              let url = 'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=' + 'MzkxODI3MTg2NQ==' + '&scene=110#wechat_redirect';
              window.location.assign(url);
            }
          }).catch(() => {
          });
        }
      });
    }
  },
  destroyed() {
    this.toast.clear();
  },
  methods: {
    back() {
      // this.onCancel();
      Dialog.alert({
        title: '系统提示',
        message: '确认关闭网页吗？',
        confirmButtonColor: '#928FFF',
        closeOnPopstate: false,
        showCancelButton: true
      }).then(() => {
        this.onCancel();
      }).catch(() => {
      });
    },
    onCancel() {
      setTimeout(function () {
        document.addEventListener('WeixinJSBridgeReady', function () {
              this.WeixinJSBridge.call('closeWindow') //安卓手机关闭代码
            },false)
        this.WeixinJSBridge.call('closeWindow') //苹果手机关闭代码
      }, 300)
    }
  }
}
</script>

<style scoped>
.div-body {
  padding-top: 15px;
}

.div-title {
  margin-left: 15px;
  /** 文本 */
  font-size: 22px;
  font-weight: 500;
  text-align: left;
  vertical-align: top;
}

.div-description {
  margin-left: 15px;
  /** 文本 */
  line-height: 2rem;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0px;
  color: rgba(108, 114, 127, 1);
  text-align: left;
  vertical-align: top;
}

.div-scan {
  display: flex;
  /* 水平居中 */
  justify-content: center;
  /* 垂直居中 */
  transform: translateY(50%);
}

.div-base {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  /* 样式 */
  width: 300px;
  height: 300px;
}

.div-circle {
  position: absolute;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: rgba(146, 143, 255, 1);
}

.div-scanButton {
  position: absolute;
  z-index: 4;
}

</style>