<template>
  <div class="apply_body">
    <div class="apply_top">
      <div class="apply_left" v-if="code !== '0'">
        <van-icon name="arrow-left" size="24" color="#928FFF" @click="applyBack" />
      </div>
      <van-search clearable placeholder="请输入产品编号 / 产品名称"
                  v-model="inputValue" @input="handleSearch(false)" />
    </div>
    <div class="apply_info">
      <van-skeleton :row="3" :loading="loading">
        <van-empty description="无发货数据" v-if="scanRecords.length === 0" />
        <van-cell-group v-if="scanRecords.length !== 0" >
          <van-cell v-for="item in scanRecordsSearch"
                    :key="item['id']"
                    :value="parseTimeStr(item['scantime'])"
                    @click="handleSkip(item['id'])" >
            <template slot="title">
              <span v-if="item['productcode'] !== ''">{{ item['productname'] }}</span>
              <span v-if="item['productcode'] !== ''">{{ item['productcode'] + '*' + item['scancode'] }}</span>
              <span v-if="item['productcode'] === ''">{{ item['scancode'] }}</span>
            </template>
            <template slot="label">
              <span>{{ item['name'] + '（' + item['address'] + '）' }}</span>
            </template>
          </van-cell>
        </van-cell-group>
      </van-skeleton>
    </div>

  </div>
</template>

<script>
import {listProduct, listProductForUser} from "@/api";
import {getUrlCode, parseTime} from "@/api/common";

export default {
  name: "MyScanList",
  data() {
    return {
      code: '',
      loading: true,
      scanRecords: [],
      scanRecordsSearch: [],
      inputValue: ''
    }
  },
  created() {
    // history.pushState(null, null, document.URL);
    // window.addEventListener('popstate', function () {
    //   history.pushState(null, null, document.URL);
    // });
  },
  mounted() {
    this.userId = this.$route.params.userId;
    if (this.userId === undefined) {
      if (window.sessionStorage.getItem('userId') === null) {
        listProduct().then(res => {
          this.scanRecords = res.data;
          this.scanRecordsSearch = this.scanRecords;
          this.loading = false;
        });
      } else {
        this.userId = window.sessionStorage.getItem('userId');
        listProductForUser('userId=' + this.userId).then(res => {
          this.scanRecords = res.data;
          this.scanRecordsSearch = this.scanRecords;
          this.loading = false;
        });
      }
    } else {
      window.sessionStorage.setItem('userId', this.userId);
      listProductForUser('userId=' + this.userId).then(res => {
        this.scanRecords = res.data;
        this.scanRecordsSearch = this.scanRecords;
        this.loading = false;
      });
    }
    this.code = getUrlCode().code;
  },
  methods: {
    applyBack() {
      if (this.userId === undefined) {
        this.$router.push('/');
      } else {
        this.$router.push({
          name: 'UserInfo',
          params: {
            userId: this.userId
          }
        });
      }
    },
    parseTimeStr(time, pattern) {
      return parseTime(time, pattern);
    },
    handleSearch() {
      let searchResult = this.scanRecords.filter((sr) => {
        let code;
        if (sr.productcode !== '') {
          code = sr.productcode + '*' + sr.scancode;
        } else {
          code = sr.scancode;
        }
        return code.indexOf(this.inputValue) !== -1;
      });
      if (searchResult.length === 0) {
        searchResult = this.scanRecords.filter((sr) => {
          return sr.productname.indexOf(this.inputValue) !== -1;
        });
      }
      this.scanRecordsSearch = searchResult;
    },
    handleSkip(id) {
      this.scanRecords.forEach((item) => {
        if (item['id'] === id) {
          window.sessionStorage.setItem('scanInfo', JSON.stringify(item));
        }
      });
      /* 跳转 */
      this.$router.push({
        name: 'ScanInfo',
        params: {
          scanId: id
        }
      });
      // let url = location.href.split('#')[0] + '#/scanInfo?id=' + id
      // window.location.assign(url);
    }
  },
}
</script>

<style scoped>
.apply_body {
  margin-left: 10px;
  margin-right: 10px;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.apply_top {
  background-color: rgb(248,248,248);
  padding-top: 15px;
  padding-bottom: 15px;
}

.apply_left {
  padding-right: 10px;
  float: left;
}

.van-search {
  padding: 0;
  height: 24px;
}

.van-search__content {
  background-color: white;
}

.apply_info {
  flex: 1;
  overflow-y: scroll;
}

.van-cell__title {
  min-width: 60%;
}

.van-cell__label {
  width: 250px;
}

.van-cell__value {
  min-width: 40%;
}

</style>