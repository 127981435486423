<template>
  <div class="apply_body">
    <van-row>
      <div class="apply_left">
        <van-icon name="arrow-left" size="24" color="#928FFF" @click="applyBack" />
      </div>
    </van-row>
    <div class="apply_imgDiv">
      <van-icon name="records" color="#928FFF" size="36" />
    </div>
    <div class="apply_title">
      <span>扫描信息</span>
    </div>
    <div class="apply_info">
      <van-field v-model.trim="date" label="日期" placeholder="请选择" input-align="right"
                 @click="onShowPopup" readonly />
      <div style="margin: 16px;">
        <van-button round block color="#928FFF" :disabled="disabled" @click="onSubmit">下载</van-button>
      </div>
    </div>

    <!-- 日期选择器弹层 -->
    <van-popup v-model="isShowPopup" position="bottom" :close-on-click-overlay="false">
      <van-datetime-picker
          v-model='currentDate'
          type="year-month"
          title="选择年月"
          columns-type="['year', 'month']"
          :max-date="maxDate"
          :min-date='minDate'
          :show-toolbar="true"
          @cancel="onCancel"
          @confirm="onConfirm"
          @change="inputChange"
      />
    </van-popup>
  </div>
</template>
<script>

import {parseTime} from "@/api/common";
import {downloadProduct} from "@/api";
import {Dialog} from "vant";

export default {
  name: "ScanDownload",
  data() {
    return {
      date: undefined,
      currentDate: new Date(),
      disabled: true,
      isShowPopup: false,
      maxDate: new Date(),
      minDate: new Date(2023, 2, 1)
    }
  },
  created() {
    // history.pushState(null, null, document.URL);
    // window.addEventListener('popstate', function () {
    //   history.pushState(null, null, document.URL);
    // });
  },
  mounted() {
    this.$nextTick(() => {
      if (this.type === 'year') {
        // 删除月份列
        let columns = this.$el.querySelectorAll('.van-picker__columns .van-picker-column')
        if (columns.length === 2) {
          columns[1].parentElement.removeChild(columns[1])
        }
      }
    })
  },
  methods: {
    applyBack() {
      this.$router.push('/manageIndex');
    },
    inputChange() {

    },
    onShowPopup() {
      this.isShowPopup = true;
    },
    onCancel() {
      this.isShowPopup = false;
      this.currentDate = new Date();
    },
    onConfirm() {
      this.isShowPopup = false;
      this.date = parseTime(this.currentDate, '{y}-{m}');
      this.disabled = false;
    },
    onSubmit() {
      downloadProduct('date=' + this.date).then(res => {
        if (res.code === 200) {
          window.location.assign(res.msg);
        } else {
          Dialog.alert({
            title: '系统提示',
            message: res.msg,
            confirmButtonColor: '#928FFF',
            closeOnPopstate: false
          }).then(() => {
          }).catch(() => {
          });
        }

      });
    }
  },
}
</script>
<style scoped>
.apply_body {
  padding-top: 15px;
  margin-left: 10px;
  margin-right: 10px;
}

.apply_left {
  float: left;
}

.apply_title {
  margin-left: 15px;
  color: rgb(126, 126, 126);
  line-height: 2rem;
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 0px;
}

.apply_info {
  margin: 0;
  padding: 10px;
  border: 1px solid rgb(224, 224, 224);
  background-color: white;
  border-radius: 15px;
}

.apply_imgDiv {
  width: 4rem;
  height: 4rem;
  margin: auto;
  border-radius: 24px;
  background-color: #E9E9FF;
  /* 叠加 */
  display: flex;
  /* 子级元素水平居中 */
  justify-content: center;
  /* 子级元素垂直居中 */
  align-items: center;
}
</style>