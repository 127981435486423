<template>
    <div>
        <div class="orderDetails_back">
            <van-nav-bar title="订单详情">
                <template #left>
                    <van-button class="orderDetails_backBut" @click="OrderDetailsBack" size="normal" :icon="left" type="default">
                        返回
                    </van-button>
                </template>
            </van-nav-bar>
        </div>
        <div class="orderDetails_data">
            <van-list>
                <van-cell>
                    <van-row>
                        <van-col class="orderDetails_cell1" span="2">序号</van-col>
                        <van-col class="orderDetails_cell1" span="7">编码</van-col>
                        <van-col class="orderDetails_cell1" span="7">名称</van-col>
                        <van-col class="orderDetails_cell1" span="3">数量</van-col>
                        <van-col class="orderDetails_cell1" span="5">期望日期</van-col>
                    </van-row>
                </van-cell>
                <van-cell v-for="(item,index) in initData" :key="index">
                    <van-row>
                        <van-col class="orderDetails_cell" span="2">{{index+1}}</van-col>
                        <van-col class="orderDetails_cell" span="7">{{item.code}}</van-col>
                        <van-col class="orderDetails_cell" span="7">{{item.name}}</van-col>
                        <van-col class="orderDetails_cell" span="3">{{item.quantity}}</van-col>
                        <van-col class="orderDetails_cell" span="5">{{item.date}}</van-col>
                    </van-row>
                </van-cell>
            </van-list>
        </div>
    </div>
</template>
<script>
import {orderMainItem} from '../api/index.js';
export default {
    data() {
        return {
            left:require('../assets/left.png'),
            initData:[],
        }
    },
    created() {
        let orderMainId=this.$route.params.orderMainId;
        orderMainItem({id:orderMainId}).then(res =>{
            this.initData=res.sqlData;
        })
    },
    filters: {
        dateFormat: (carTime) => {
            let now = new Date(carTime)
            let y = now.getFullYear()
            let m = (now.getMonth() + 1).toString().padStart(2, '0')
            let d = now.getDate().toString().padStart(2, '0')
            return `${y}/${m}/${d}`
        }
    },
    mounted() {
        
    },
    methods: {
        OrderDetailsBack(){
            this.$router.push('/OrderInquiry');
        }
    },
}
</script>
<style scoped>
.orderDetails_back{
    background-color: white; 
}
.orderDetails_backBut{
    padding-left: 0px;
    padding-right: 0px;
    border: none;
}
.orderDetails_data{
    margin-top: 5px;
    border: 1px solid rgb(238, 238, 238);
    height: 92vh;
    overflow:auto;
}
.orderDetails_cell1{
   font-size: 12px;
   text-align: center;
}
.orderDetails_cell{
    font-size: 11px;
    color: rgb(124, 124, 124);
    text-align: center;
}
</style>