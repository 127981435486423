<template>
  <div class="div-body">
    <van-row>
      <van-col span="20">
        <div class="div-title">
          <span>发货扫描</span>
        </div>
        <div class="div-description">
          <span>发货扫描日期为三包凭证</span>
        </div>
      </van-col>
      <van-col span="4">
        <div class="apply_button">
          <van-icon name="user-circle-o" size="36" color="#928FFF" @click="applyUser" />
          <van-icon name="thumb-circle-o" size="36" color="#928FFF" @click="manage" v-if="this.flag === 1" />
<!--          <van-icon name="scan" size="36" color="#928FFF" @click="applyResult('CODE_128,J786M-3401010HL*230304-001')" />-->
        </div>
      </van-col>
    </van-row>
    <div class="div-scan">
      <div class="div-base">
        <div class="div-circle"/>
        <div class="div-circle"/>
        <div class="div-circle"/>
        <div class="div-circle"/>
        <div class="div-scanButton" @click="handleScan">
          <img :src="scanButton" />
        </div>
      </div>
    </div>
    <div class="div-history" @click="applyProduct">
      <div class="div-historyBox">
        <img :src="historyBox"/>
        <div class="div-historyShape">
          <img :src="historyShape"/>
        </div>
      </div>
      <div class="div-historyTitle">
        <span>您的发货数量：</span>
      </div>
      <div class="div-historyValue">
        <span class="div-value">{{ count }}</span>
        <span class="div-unit">件</span>
      </div>
      <div class="div-historyCompany">
        <span>{{ company }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import {countProduct, getConfig} from '@/api';
import {getUrlCode} from "@/api/common";
import {Dialog, Toast} from "vant";
import wx from "weixin-js-sdk";
// import preventBack from 'vue-prevent-browser-back';

export default {
  name: "Home",
  // mixins: [preventBack],
  data() {
    return {
      base: require('@/assets/scanshipment/Base1.svg'),
      base1: require('@/assets/scanshipment/Base1.svg'),
      base2: require('@/assets/scanshipment/Base2.svg'),
      base3: require('@/assets/scanshipment/Base3.svg'),
      scanButton: require('@/assets/scanshipment/ScanButton.svg'),
      historyBox: require('@/assets/scanshipment/HistoryBox.svg'),
      historyShape: require('@/assets/scanshipment/HistoryShape.svg'),
      company: '',
      count: undefined,
      state: undefined,
      flag: undefined,
      scanConfig: {}
    }
  },
  created() {
    history.pushState(null, null, document.URL);
    window.addEventListener('popstate', function () {
      history.pushState(null, null, document.URL);
    });
  },
  mounted() {
    let code = getUrlCode().code;
    if (code === undefined || code == null) {
      window.localStorage.clear();
      this.$router.push('/index');
    } else {
      let userInfo = window.localStorage.getItem('userInfo');
      if (userInfo == null) {
        this.$router.push('/login');
      } else {
        let userJson = JSON.parse(userInfo);
        this.state = userJson['state'];
        this.flag = userJson['flag'];
        this.completeUserInfo(userJson);
        countProduct().then(res => {
          this.count = res.data;
        });
        if (userJson['company'] !== undefined) {
          this.company = userJson['company'];
        }
      }
    }
  },
  methods: {
    completeUserInfo(userJson) {
      if (userJson == null) {
        return false;
      }
      let phone = userJson['phone'];
      let company = userJson['company'];
      let name = userJson['name'];
      if (phone == null || company == null || name == null) {
        Dialog.alert({
          title: '系统提示',
          message: '您的个人信息不完整，需要补全！',
          confirmButtonColor: '#928FFF',
          closeOnPopstate: false,
          showCancelButton: true
        }).then(() => {
          this.$router.push('/myInfo');
        }).catch(() => {
          this.onCancel();
        });
        return false;
      }
    },
    applyUser() {
      this.$router.push('/myInfo');
    },
    applyProduct() {
      this.$router.push('/myScanList');
    },
    manage() {
      let url = location.href.split('#')[0] + '#/manageIndex'
      window.location.assign(url);
    },
    applyResult(scanCode) {
      /*this.$router.push({
        path: '/scanResult',
        query: {scanCode: scanCode}
      });*/
      let url = location.href.split('#')[0] + '#/scanResult?scanCode=' + scanCode
      alert(url)
      window.location.assign(url);
    },
    handleScan() {
      if (this.state === -1) {
        Dialog.alert({
          title: '系统提示',
          message: '您目前属于【认证未通过】的用户，暂时无法使用该功能。\n请按要求重新修改您的资料，等待管理员再次审核。',
          confirmButtonColor: '#928FFF',
          closeOnPopstate: false
        }).then(() => {
        }).catch(() => {
        });
        return;
      } else if (this.state !== 0) {
        Dialog.alert({
          title: '系统提示',
          message: '您目前属于【未认证】的用户，暂时无法使用该功能。\n请等待管理员审核您的资料。',
          confirmButtonColor: '#928FFF',
          closeOnPopstate: false
        }).then(() => {
        }).catch(() => {
        });
        return;
      }
      let toast = Toast.loading({message: '加载配置中...', forbidClick: true, duration: 0, overlay: true});
      getConfig('url=' + encodeURIComponent(location.href.split('#')[0])).then(res => {
        this.scanConfig = res.data;
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: this.scanConfig['appId'], // 必填，公众号的唯一标识
          timestamp: this.scanConfig['timestamp'], // 必填，生成签名的时间戳
          nonceStr: this.scanConfig['nonceStr'], // 必填，生成签名的随机串
          signature: this.scanConfig['signature'],// 必填，签名
          jsApiList: ['scanQRCode'] // 必填，需要使用的JS接口列表
        });
        wx.ready(function () {
          setTimeout(() => { }, 1200);
          wx.scanQRCode({
            needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
            scanType: ['qrCode', 'barCode'], // 可以指定扫二维码还是一维码，默认二者都有
            success: function (res) {
              // alert(JSON.stringify(res));
              let url = location.href.split('#')[0] + '#/scanResult?scanCode=' + res.resultStr
              // alert(url);
              window.location.assign(url);
            }
          });
        });
        wx.error(function (res) {
          Dialog.alert({
            title: '系统提示',
            message: '扫码出错：' + res.errMsg,
            confirmButtonColor: '#928FFF',
            closeOnPopstate: false
          }).then(() => {
            this.$router.push('/');
          }).catch(() => {
          });
        });
        toast.clear();
      });
    },
    back() {
      // this.onCancel();
      Dialog.alert({
        title: '系统提示',
        message: '确认关闭网页吗？',
        confirmButtonColor: '#928FFF',
        closeOnPopstate: false,
        showCancelButton: true
      }).then(() => {
        this.onCancel();
      }).catch(() => {
      });
    },
    onCancel() {
      setTimeout(function () {
        document.addEventListener('WeixinJSBridgeReady', function () {
          this.WeixinJSBridge.call('closeWindow') //安卓手机关闭代码
        },false)
        this.WeixinJSBridge.call('closeWindow') //苹果手机关闭代码
      }, 300)
    }
  }
}
</script>

<style scoped>
.apply_button {
  writing-mode: vertical-lr;
  float: right;
  margin-right: 15px;
}

.div-body {
  padding-top: 15px;
}

.div-title {
  margin-left: 15px;
  /** 文本 */
  font-size: 22px;
  font-weight: 500;
  text-align: left;
  vertical-align: top;
}

.div-description {
  margin-left: 15px;
  /** 文本 */
  line-height: 2rem;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0px;
  color: rgba(108, 114, 127, 1);
  text-align: left;
  vertical-align: top;
}

.div-scan {
  display: flex;
  /* 水平居中 */
  justify-content: center;
  /* 垂直居中 */
  transform: translateY(50%);
}

.div-base {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  /* 样式 */
  width: 300px;
  height: 300px;
}

.div-circle {
  position: absolute;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: rgba(146, 143, 255, 1);
}

.div-circle:nth-child(1) {
  animation: anim 3s linear infinite;
}

.div-circle:nth-child(2) {
  animation: anim 3s linear 0.8s infinite;
}

.div-circle:nth-child(3) {
  animation: anim 3s linear 1.6s infinite;
}

@keyframes anim {
  from {
    opacity: 1;
    transform: scale(0);
  }
  to {
    opacity: 0;
    transform: scale(4);
  }
}

.div-base1 {
  position: absolute;
  z-index: 1;
}

.div-base2 {
  position: absolute;
  z-index: 2;
}

.div-base3 {
  position: absolute;
  z-index: 3;
}

.div-scanButton {
  position: absolute;
  z-index: 4;
}

.div-history {
  /* 位于底部 */
  position: fixed;
  bottom: 30px;
  /* 水平居中 */
  margin-left: 50%;
  margin-right: 50%;
  transform: translate(-50%);
  /* 叠加 */
  display: flex;
  justify-content: center;
}

.div-historyBox {
  position: relative;
  display: flex;
  overflow: hidden;
  border-radius: 20px;
}

.div-historyShape {
  position: absolute;
  margin-top: -13px;
}

.div-historyTitle {
  position: absolute;
  z-index: 1;
  margin-top: 35px;
  width: 280px;
  /** 文本 */
  line-height: 1rem;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0px;
  color: rgba(255, 255, 255, 1);
  vertical-align: top;
}

.div-historyValue {
  position: absolute;
  z-index: 1;
  margin-top: 70px;
  width: 280px;
}

.div-historyValue > .div-value {
  /** 文本 */
  line-height: 2rem;
  font-size: 36px;
  font-weight: 500;
  letter-spacing: 0px;
  color: rgba(255, 255, 255, 1);
}

.div-historyValue > .div-unit {
  padding-left: 8px;
  /** 文本 */
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0px;
  color: rgba(255, 255, 255, 0.4);
  vertical-align: bottom;
}

.div-historyCompany {
  position: absolute;
  z-index: 1;
  margin-top: 120px;
  width: 280px;
  /** 文本 */
  font-size: 13px;
  font-weight: 200;
  letter-spacing: 0px;
  color: rgba(255, 255, 255, 1);
  text-align: left;
  vertical-align: top;
}
</style>