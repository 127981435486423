<template>
  <div class="gzh_app" :style="'min-height: '+ClientHeight+'px;'">
    <router-view></router-view>
  </div>
</template>

<script>
import {authorize} from "@/api";
// import {Dialog} from "vant";

export default {
  name: "Index",
  data() {
    return {
      ClientHeight: document.documentElement.clientHeight,
    }
  },
  created() {
    let url = '';
    authorize().then(res => {
      url = res;
      window.location.replace(url);
      /*Dialog.alert({
        title: '系统提示',
        message: url,
        confirmButtonColor: '#928FFF',
        closeOnPopstate: false
      }).then(() => {
        window.location.assign(url);
      }).catch(() => {
      });*/
    });
  }
}
</script>

<style scoped>
.gzh_app {
  background-color: rgb(248, 248, 248);
}
</style>