<template>
  <div class="apply_body">
    <van-row>
      <div class="apply_left">
        <van-icon name="arrow-left" size="24" color="#928FFF" @click="applyBack" v-if="code !== '0'" />
        <van-icon name="cross" size="24" color="#928FFF" @click="onCancel" v-if="code === '0'" />
      </div>
    </van-row>
    <div class="apply_userInfo_imgDiv">
      <img :src="headimgurl" class="apply_user_imgurl"/>
      <van-badge color="#928FFF" v-if="this.flag === 1">
        <div class="child" />
        <template #content>
          <van-icon name="manager-o" class="badge-icon" />
        </template>
      </van-badge>
    </div>
    <div class="apply_userInfo_nickname">
      <span>{{ nickname }}</span>
    </div>
    <div class="apply_title">
      <span>基本信息</span>
    </div>
    <van-form class="apply_userInfo" @submit="onSubmit">
      <van-field required
                 v-model.trim="company"
                 type="textarea"
                 label="公司名称"
                 placeholder="公司名称"
                 @input="inputChange"
                 maxlength="30"
                 show-word-limit
                 :rules="[{ required: true, message: '公司名称不能为空' },
                     { pattern: /^(?!\s*$)[A-Za-z0-9._\-\(\)\u4e00-\u9fa5\（）\s-]+$/, message: '请输入正确的公司名称' }]"
      />
      <van-field required
                 v-model.trim="name"
                 label="真实姓名"
                 placeholder="真实姓名"
                 @input="inputChange"
                 :rules="[{ required: true, message: '真实姓名不能为空' },
                     { pattern: /^[\u4E00-\u9FA5]{2,4}$/, message: '请输入正确的姓名' }]"
      />
      <van-field required
                 v-model.trim="phone"
                 type="digit"
                 label="联系电话"
                 placeholder="联系电话"
                 @input="inputChange"
                 :rules="[{ required: true, message: '手机号码不能为空' },
                     { pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/, message: '请输入正确的手机号码' }]"
      />
      <van-cell title="是否认证">
        <van-tag plain type="success" size="medium" v-if="this.state === 0">已认证</van-tag>
        <van-tag plain type="danger" size="medium" v-if="this.state === -1">认证未通过</van-tag>
        <van-tag plain type="danger" size="medium" v-if="this.state === 1">未认证</van-tag>
        <span v-if="this.state > 1" style="font-size: 10px;margin-right: 10px;">提醒管理员尽快审核 →</span>
        <van-tag plain type="warning" size="medium" v-if="this.state === 2" @click="remind">审核中</van-tag>
      </van-cell>
      <div style="margin: 16px;">
        <van-button round block color="#928FFF" native-type="submit" :disabled="disabled">保存</van-button>
      </div>
    </van-form>

    <van-dialog v-model="dialogShow" :close-on-popstate="false" :show-confirm-button="false">
      <div class="apply_message">
        <div class="apply_message_title">
          <span>系统提示</span>
        </div>
        <div class="apply_message_content">是否提醒管理员审核您的资料？<br/>{{'（每隔 '+ this.freq + ' 小时可提醒一次）'}}</div>
        <div class="apply_message_button">
          <van-button class="apply_message_button_cancel" block @click="dialogShow = false">取消</van-button>
          <van-button class="apply_message_button_confirm" block @click="handleConfirm" v-if="this.time === 0">确认</van-button>
          <van-button class="apply_message_button_confirm" block v-if="this.time !== 0">
            <van-count-down @finish="time = 0" :time="time" />
          </van-button>
        </div>
      </div>
    </van-dialog>
  </div>
</template>
<script>
import {getOneUser, messageInfo, sendUserCheck, updateUser} from '@/api/index.js';
import {Dialog} from 'vant';
import {getUrlCode} from "@/api/common";

export default {
  name: "MyInfo",
  data() {
    return {
      userId: undefined,
      code: '',
      headimgurl: '',
      nickname: '',
      company: '',
      name: '',
      phone: '',
      state: undefined,
      flag: undefined,
      disabled: true,
      dialogShow: false,
      time: 0,
      freq: 0
    }
  },
  created() {
    // history.pushState(null, null, document.URL);
    // window.addEventListener('popstate', function () {
    //   history.pushState(null, null, document.URL);
    // });
  },
  mounted() {
    let userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
    this.code = getUrlCode().code;
    if (this.code === '0') {
      this.userId = getUrlCode().userId;
      if (this.userId !== userInfo['id']) {
        Dialog.alert({
          title: '系统提示',
          message: '检测到不是本人登录',
          confirmButtonColor: '#928FFF',
          closeOnPopstate: false
        }).then(() => {
          this.onCancel();
        }).catch(() => {
        });
      }
      getOneUser(this.userId).then(res => {
        this.state = res.data['state'];
      });
    } else {
      this.state = userInfo['state'];
    }
    this.headimgurl = userInfo['headimgurl'];
    this.nickname = userInfo['nickname'];
    this.company = userInfo['company'];
    this.name = userInfo['name'];
    this.phone = userInfo['phone'];
    this.flag = userInfo['flag'];
    messageInfo('type=userCheck').then(res => {
      this.time = res.data['nextTime'];
      this.freq = res.data['freq'];
    });
  },
  methods: {
    applyBack() {
      this.$router.push('/');
    },
    onCancel() {
      setTimeout(function () {
        document.addEventListener('WeixinJSBridgeReady', function () {
          this.WeixinJSBridge.call('closeWindow') //安卓手机关闭代码
        },false)
        this.WeixinJSBridge.call('closeWindow') //苹果手机关闭代码
      }, 300)
    },
    onSubmit() {
      updateUser('company=' + this.company + '&name=' + this.name + '&phone=' + this.phone).then(res => {
        Dialog.alert({
          title: '系统提示',
          message: res.msg,
          confirmButtonColor: '#928FFF',
          closeOnPopstate: false
        }).then(() => {
          window.localStorage.setItem('userInfo', JSON.stringify(res.data));
          if (this.code === '0') {
            this.onCancel();
          } else {
            this.$router.push('/');
          }
        });
      })
    },
    inputChange() {
      let userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
      if (this.company !== userInfo['company'] || this.name !== userInfo['name'] || this.phone !== userInfo['phone']) {
        this.disabled = false;
      } else {
        this.disabled = true;
      }
      if (userInfo['state'] === 2) {// 审核中不允许修改
        this.disabled = true;
      }
    },
    remind() {
      this.dialogShow = true;
    },
    handleConfirm() {
      sendUserCheck().then(res => {
        this.time = res.data;
        Dialog.alert({
          title: '系统提示',
          message: res.msg,
          confirmButtonColor: '#928FFF',
          closeOnPopstate: false
        }).then(() => {
          this.dialogShow = false;
        }).catch(() => {
        });
      });
    }
  },
}
</script>
<style scoped>
.apply_body {
  padding-top: 15px;
  margin-left: 10px;
  margin-right: 10px;
}

.apply_left {
  float: left;
}

.apply_title {
  margin-left: 15px;
  color: rgb(126, 126, 126);
  line-height: 2rem;
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 0px;
}

.apply_userInfo {
  padding: 10px;
  border: 1px solid rgb(224, 224, 224);
  background-color: white;
  border-radius: 15px;
}

.apply_userInfo_imgDiv {
  width: 4rem;
  height: 4rem;
  margin: auto;
}

.apply_user_imgurl {
  width: 100%;
  height: 100%;
  border-radius: 2rem;
}

.badge-icon {
  display: block;
  font-size: 10px;
  line-height: 16px;
}

.apply_userInfo_nickname {
  text-align: center;
  color: darkgray;
  line-height: 2rem;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0px;
  padding-top: 10px;
}

.apply_message {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.apply_message_title {
  width: 100%;
  padding-top: 26px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  font-size: 16px;
}

.apply_message_content {
  padding: 8px 24px 26px 24px;
  line-height: 20px;
  text-align: center;
  font-size: 14px;
  color: #646566;
}

.apply_message_button {
  width: 100%;
  border-top: 1px solid #ebedf0;
  display: flex;
  align-items: center;
}

.apply_message_button_cancel {
  width: 50%;
  height: 48px;
  border: none;
  border-right: 1px solid #ebedf0;
  font-size: 16px;
}

.apply_message_button_confirm {
  width: 50%;
  height: 48px;
  border: none;
  font-size: 16px;
  color: #928FFF;
}

.apply_message_button_confirm::before{
  background-color: #928FFF;
}

.van-count-down {
  color: #928FFF;
}
</style>