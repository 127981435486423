<template>
  <div class="apply_body">
    <van-row>
      <div class="apply_left">
        <van-icon name="close" size="20" color="#928FFF" @click="applyBack" />
      </div>
    </van-row>
    <van-row justify="center">
      <van-col span="24">
        <div class="apply_imgDiv_success" v-if="flag === 'success'">
          <van-icon name="completed" color="#8EE04E" size="36" />
        </div>
        <div class="apply_imgDiv_error" v-if="flag !== 'success'">
          <van-icon name="failure" color="#FF7D7D" size="36" />
        </div>
        <div class="apply_message">
          <span>{{ message }}</span>
        </div>
      </van-col>
    </van-row>
    <van-cell-group inset class="apply_info" v-if="flag !== 'invalid'" >
      <van-field readonly input-align="right" v-model.trim="productname" label="产品名称" v-if="this.productname !== ''"/>
      <van-field readonly input-align="right" v-model.trim="productcode" label="产品图号" v-if="this.productname !== ''"/>
      <van-field readonly input-align="right" v-model.trim="scancode" label="台账码" v-if="this.productname !== ''"/>
      <van-field readonly input-align="right" v-model.trim="scancode" label="产品编号" v-if="this.productname === ''"/>
      <van-field readonly input-align="right" v-model.trim="address" label="扫描地点"/>
      <van-field readonly input-align="right" v-model.trim="scantimeStr" label="扫描时间"/>
    </van-cell-group>
  </div>
</template>
<script>
import {scanProduct} from "@/api";
import {parseTime} from "@/api/common";
import {Dialog} from "vant";

export default {
  name: "ScanResult",
  data() {
    return {
      flag: '',
      message: '',
      productname: '',
      productcode: '',
      scancode: '',
      address: '',
      scantime: '',
      scantimeStr: ''
    }
  },
  created() {
    // history.pushState(null, null, document.URL);
    // window.addEventListener('popstate', function () {
    //   history.pushState(null, null, document.URL);
    // });
  },
  mounted() {
    let url = location.href;
    // alert(url)
    let params = url.split('=');
    let code = decodeURIComponent(params[params.length - 1]);

    scanProduct("code=" + code).then((res) => {
      this.message = res.msg;
      this.flag = 'error';
      if (res.data !== undefined) {
        this.productname = res.data['productname'];
        this.productcode = res.data['productcode'];
        this.scancode = res.data['scancode'];
        this.address = res.data['address'];
        this.scantime = res.data['scantime'];
        this.scantimeStr = parseTime(this.scantime);
        if (res.code === 200) {
          this.flag = 'success';
        }
      } else {
        this.flag = 'invalid';
        Dialog.alert({
          title: '系统提示',
          message: '扫描结果为【' + code + '】，系统无法识别！',
          confirmButtonColor: '#928FFF',
          closeOnPopstate: false
        }).then(() => {
        }).catch(() => {
        });
      }
    });
  },
  methods: {
    applyBack() {
      this.$router.push('/');
    }
  },
}
</script>
<style scoped>
.apply_body {
  padding-top: 20px;
  margin-left: 2rem;
  margin-right: 2rem;
}

.apply_left {
  float: left;
}

.apply_info {
  padding: 10px;
  margin: 0px;
  border: 1px rgb(224, 224, 224);
  background-color: white;
  border-radius: 16px;
}

.apply_imgDiv_success {
  width: 4rem;
  height: 4rem;
  margin: auto;
  border-radius: 24px;
  background-color: #E8F9DC;
  /*border: 1px solid #8EE04E;*/
  /* 叠加 */
  display: flex;
  /* 子级元素水平居中 */
  justify-content: center;
  /* 子级元素垂直居中 */
  align-items: center;
}

.apply_imgDiv_error {
  width: 4rem;
  height: 4rem;
  margin: auto;
  border-radius: 24px;
  background-color: #FFE5E5;
  /*border: 1px solid #FF7D7D;*/
  /* 叠加 */
  display: flex;
  /* 子级元素水平居中 */
  justify-content: center;
  /* 子级元素垂直居中 */
  align-items: center;
}

.apply_message {
  text-align: center;
  line-height: 4rem;
  font-size: 1rem;
  font-weight: 300;
  letter-spacing: 0px;
  color: rgba(108, 114, 127, 1);
}
</style>