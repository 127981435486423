<template>
  <div class="apply_body">
    <van-row>
      <div class="apply_left">
        <van-icon name="arrow-left" size="24" color="#928FFF" @click="applyBack" v-if="code !== '0'" />
        <van-icon name="cross" size="24" color="#928FFF" @click="onCancel" v-if="code === '0'" />
      </div>
      <div class="apply_right" v-if="this.flag === 1">
        <van-icon name="edit" size="24" color="#928FFF" @click="edit" />
      </div>
      <div class="apply_right">
        <span class="apply_right_text" v-if="this.flag !== 1">如果扫描信息有误，请联系管理员 →</span>
        <van-icon name="service-o" size="24" color="#928FFF" @click="remind" />
      </div>
    </van-row>
    <div class="apply_imgDiv">
      <van-icon name="description" color="#928FFF" size="36" />
    </div>
    <div class="apply_title">
      <span>扫描信息</span>
    </div>
    <van-form class="apply_info">
      <van-field disabled input-align="right" v-model.trim="productname" label="产品名称" v-if="this.productname !== ''"/>
      <van-field :disabled="!editAble" @input="inputChange"
                 :required="editAble" :rules="[{ required: true, message: '产品图号不能为空' }]"
                 input-align="right" v-model.trim="productcode" label="产品图号" v-if="this.productname !== ''"/>
      <van-field :disabled="!editAble" @input="inputChange"
                 :required="editAble" :rules="[{ required: true, message: '台账码不能为空' }]"
                 input-align="right" v-model.trim="scancode" label="台账码" v-if="this.productname !== ''"/>
      <van-field :disabled="!editAble" @input="inputChange"
                 :required="editAble" :rules="[{ required: true, message: '产品编号不能为空' }]"
                 input-align="right" v-model.trim="scancode" label="产品编号" v-if="this.productname === ''"/>
      <van-field disabled input-align="right" v-model.trim="address" label="扫描地点"/>
      <van-field disabled input-align="right" v-model.trim="ip" label="扫描IP" v-if="this.flag === 1"/>
      <van-field disabled input-align="right" v-model.trim="scantime" label="扫描时间"/>
      <van-row v-if="this.editAble === true">
        <van-col class="apply_edit_button" span="8">
          <van-button round size="small" icon="delete-o" type="danger" @click="editDelete">删除</van-button>
        </van-col>
        <van-col class="apply_edit_button" span="8">
          <van-button round size="small" icon="success" color="#928FFF" @click="editSubmit" :disabled="disabled">保存</van-button>
        </van-col>
        <van-col class="apply_edit_button" span="8">
          <van-button round size="small" icon="cross" type="default" @click="editCancel">取消</van-button>
        </van-col>
      </van-row>
    </van-form>
    <div class="apply_title" v-if="this.flag === 1">
      <span>客户信息</span>
    </div>
    <van-cell-group inset class="apply_info" v-if="this.flag === 1">
      <van-cell title="微信昵称" :value="nickname" />
      <van-cell title="公司名称" :value="company" />
      <van-cell title="真实姓名" :value="name" />
      <van-cell title="联系电话">
        <a :href="'tel:' + phone + '#mp.weixin.qq.com'" class="phone">
          <span>{{ phone }}</span>
          <van-icon name="phone-o" />
        </a>
      </van-cell>
    </van-cell-group>

    <van-dialog v-model="dialogShow" :close-on-popstate="false" :show-confirm-button="false">
      <div class="apply_message">
        <div class="apply_message_title">
          <span class="apply_message_title_text">留言板</span>
        </div>
        <van-form class="apply_message_content" @submit="messageSubmit">
          <van-field v-model="message" placeholder="请具体描述扫描信息的问题"
                     clearable required :rules="[{ required: true, message: '留言不能为空' }]"
                     type="textarea" rows="8" autosize
                     maxlength="120" show-word-limit />
          <div class="apply_message_button">
            <van-button class="apply_message_button_cancel" block @click="messageCancel">取消</van-button>
            <van-button class="apply_message_button_confirm" block native-type="submit">确认</van-button>
          </div>
        </van-form>
      </div>
    </van-dialog>
  </div>
</template>
<script>
import {getUrlCode, parseTime} from "@/api/common";
import {Dialog} from "vant";

export default {
  name: "ScanResult",
  data() {
    return {
      scanId: undefined,
      code: '',
      productname: '',
      productcode: '',
      scancode: '',
      address: '',
      ip: '',
      scantime: '',
      headimgurl: '',
      nickname: '',
      company: '',
      name: '',
      phone: '',
      flag: undefined,
      editAble: false,
      disabled: true,
      dialogShow: false,
      message: ''
    }
  },
  created() {
    // history.pushState(null, null, document.URL);
    // window.addEventListener('popstate', function () {
    //   history.pushState(null, null, document.URL);
    // });
  },
  mounted() {
    this.scanId = this.$route.params.scanId;
    if (this.scanId === undefined) {
      this.scanId = getUrlCode().scanId;
    }
    this.code = getUrlCode().code;

    let scanInfo = JSON.parse(window.sessionStorage.getItem('scanInfo'));
    this.productname = scanInfo['productname'];
    this.productcode = scanInfo['productcode'];
    this.scancode = scanInfo['scancode'];
    this.address = scanInfo['address'];
    this.ip = scanInfo['ip'];
    this.scantime = parseTime(scanInfo['scantime']);
    this.headimgurl = scanInfo['headimgurl'];
    this.nickname = scanInfo['nickname'];
    this.company = scanInfo['company'];
    this.name = scanInfo['name'];
    this.phone = scanInfo['phone'];

    let userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
    this.flag = userInfo['flag'];
  },
  methods: {
    applyBack() {
      window.history.back();
      // if (this.flag === 1) {
      //   this.$router.push('/scanList');
      // } else {
      //   this.$router.push('/myScanList');
      // }
    },
    onCancel() {
      setTimeout(function () {
        document.addEventListener('WeixinJSBridgeReady', function () {
          this.WeixinJSBridge.call('closeWindow') //安卓手机关闭代码
        },false)
        this.WeixinJSBridge.call('closeWindow') //苹果手机关闭代码
      }, 300)
    },
    remind() {
      this.message = '';
      this.dialogShow = true;
    },
    messageCancel() {
      this.message = ' ';
      this.dialogShow = false;
    },
    messageSubmit() {
      this.dialogShow = false;
    },
    edit() {
      if (this.editAble === false) {
        Dialog.alert({
          title: '系统提示',
          message: '是否开始编辑扫描信息？',
          confirmButtonColor: '#928FFF',
          closeOnPopstate: false,
          showCancelButton: true
        }).then(() => {
          this.editAble = true;
        }).catch(() => {
        });
      }
    },
    inputChange() {
      let scanInfo = JSON.parse(window.sessionStorage.getItem('scanInfo'));
      if (this.productcode !== scanInfo['productcode'] || this.scancode !== scanInfo['scancode']) {
        this.disabled = false;
      } else {
        this.disabled = true;
      }
    },
    editCancel() {
      this.editAble = false;
      let scanInfo = JSON.parse(window.sessionStorage.getItem('scanInfo'));
      this.productcode = scanInfo['productcode'];
      this.scancode = scanInfo['scancode'];
    },
    editSubmit() {
      Dialog.alert({
        title: '系统提示',
        message: '是否保存修改内容？',
        confirmButtonColor: '#928FFF',
        closeOnPopstate: false,
        showCancelButton: true
      }).then(() => {
        this.editAble = false;
      }).catch(() => {
      });
    },
    editDelete() {
      Dialog.alert({
        title: '系统提示',
        message: '是否删除该条扫描信息？',
        confirmButtonColor: '#928FFF',
        closeOnPopstate: false,
        showCancelButton: true
      }).then(() => {
        this.$router.push('/scanList');
      }).catch(() => {
      });
    }
  },
}
</script>
<style scoped>
.apply_body {
  padding-top: 15px;
  margin-left: 10px;
  margin-right: 10px;
}

.apply_left {
  float: left;
}

.apply_right {
  margin-left: 10px;
  float: right;
  background-color: rgb(248, 248, 248);
  /* 叠加 */
  display: flex;
  /* 子级元素垂直居中 */
  align-items: center;
}

.apply_right_text {
  font-size: 10px;
  margin-right: 5px;
  color: #969799;
}

.apply_title {
  margin-left: 15px;
  color: rgb(126, 126, 126);
  line-height: 2rem;
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 0px;
}

.apply_info {
  margin: 0;
  padding: 10px;
  border: 1px rgb(224, 224, 224);
  background-color: white;
  border-radius: 15px;
}

.apply_imgDiv {
  width: 4rem;
  height: 4rem;
  margin: auto;
  border-radius: 24px;
  background-color: #E9E9FF;
  /* 叠加 */
  display: flex;
  /* 子级元素水平居中 */
  justify-content: center;
  /* 子级元素垂直居中 */
  align-items: center;
}

.phone {
  /* 叠加 */
  display: flex;
  flex-direction: row-reverse;
  /* 子级元素垂直居中 */
  align-items: center;
}

.apply_message {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.apply_message_title {
  width: 100%;
  height: 48px;
  background-color: #E9E9FF;
  display: flex;
  align-items: center;
  justify-content: center;
}

.apply_message_title_text {
  text-align: center;
  font-size: 16px;
  letter-spacing: 5px;
  line-height: 2rem;
  color: #928FFF;
}

.apply_message_content {
  width: 100%;
  height: 305px;
  position: relative;
}

.apply_message_button {
  width: 100%;
  border-top: 1px solid #ebedf0;
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
}

.apply_message_button_cancel {
  width: 50%;
  height: 48px;
  border: none;
  border-right: 1px solid #ebedf0;
  font-size: 16px;
}

.apply_message_button_confirm {
  width: 50%;
  height: 48px;
  border: none;
  font-size: 16px;
  color: #928FFF;
}

.apply_message_button_confirm::before{
  background-color: #928FFF;
}

.apply_edit_button {
  margin-top: 10px;
  /* 叠加 */
  display: flex;
  /* 子级元素水平居中 */
  justify-content: center;
}

.van-cell__title {
  min-width: 25%;
}

.van-cell__label {

}

.van-cell__value {
  min-width: 75%;
}
</style>