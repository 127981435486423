<template>
  <div class="apply_body">
    <van-row>
      <div class="apply_left">
        <van-icon name="arrow-left" size="24" color="#928FFF" @click="applyBack" />
      </div>
    </van-row>
    <div class="apply_userInfo_imgDiv">
      <img :src="headimgurl" class="apply_user_imgurl"/>
      <van-badge color="#928FFF" v-if="this.flag === 1">
        <div class="child" />
        <template #content>
          <van-icon name="manager-o" class="badge-icon" />
        </template>
      </van-badge>
    </div>
    <div class="apply_userInfo_nickname">
      <span>{{ nickname }}</span>
    </div>
    <div class="apply_title">
      <span>基本信息</span>
    </div>
    <van-cell-group inset class="apply_userInfo" >
      <van-cell title="公司名称" :value="company" />
      <van-cell title="真实姓名" :value="name" />
      <van-cell title="联系电话">
        <a :href="'tel:' + phone + '#mp.weixin.qq.com'" class="phone">
          <span>{{ phone }}</span>
          <van-icon name="phone-o" />
        </a>
      </van-cell>
      <van-cell title="是否认证">
        <van-tag plain type="success" size="medium" v-if="this.state === 0">已认证</van-tag>
        <van-tag plain type="warning" size="medium" v-if="this.state === -1">认证未通过</van-tag>
        <van-tag plain type="danger" size="medium" v-if="this.state === 1">未认证</van-tag>
        <van-tag plain type="warning" size="medium" v-if="this.state === 2">审核中</van-tag>
      </van-cell>
    </van-cell-group>
    <div class="apply_title">
      <span>扫描信息</span>
    </div>
    <van-cell-group inset class="apply_userInfo" >
      <van-cell title="发货数量" :value="count" @click="handleSkip"/>
    </van-cell-group>
  </div>
</template>
<script>


import {countProductForUser} from "@/api";

export default {
  name: "UserInfo",
  data() {
    return {
      userId: undefined,
      count: 0,
      headimgurl: '',
      nickname: '',
      company: '',
      name: '',
      phone: '',
      state: undefined,
      flag: undefined
    }
  },
  created() {
    // history.pushState(null, null, document.URL);
    // window.addEventListener('popstate', function () {
    //   history.pushState(null, null, document.URL);
    // });
  },
  mounted() {
    this.userId = this.$route.params.userId;
    if (this.userId === undefined) {
      if (window.sessionStorage.getItem('userId') === null) {
        let userInfo = JSON.parse(window.sessionStorage.getItem('user'));
        this.userId = userInfo['id'];
      } else {
        this.userId = window.sessionStorage.getItem('userId');
      }
    } else {
      window.sessionStorage.setItem('userId', this.userId);
    }

    countProductForUser('userId=' + this.userId).then(res => {
      this.count = res.data;
    });

    let userInfo = JSON.parse(window.sessionStorage.getItem('user'));
    this.headimgurl = userInfo['headimgurl'];
    this.nickname = userInfo['nickname'];
    this.company = userInfo['company'];
    this.name = userInfo['name'];
    this.phone = userInfo['phone'];
    this.state = userInfo['state'];
    this.flag = userInfo['flag'];
    if (this.state === 0) {
      this.stateBool = true;
    } else {
      this.stateBool = false;
    }
  },
  destroyed() {
    window.sessionStorage.removeItem('userId');
  },
  methods: {
    applyBack() {
      this.$router.push('/userList');
    },
    handleSkip() {
      this.$router.push({
        name: 'MyScanList',
        params: {
          userId: this.userId
        }
      });
    }
  },
}
</script>
<style scoped>
.apply_body {
  padding-top: 15px;
  margin-left: 10px;
  margin-right: 10px;
}

.apply_left {
  float: left;
}

.apply_title {
  margin-left: 15px;
  color: rgb(126, 126, 126);
  line-height: 2rem;
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 0px;
}

.apply_userInfo {
  margin: 0;
  padding: 10px;
  border: 1px solid rgb(224, 224, 224);
  background-color: white;
  border-radius: 15px;
}

.apply_userInfo_imgDiv {
  width: 4rem;
  height: 4rem;
  margin: auto;
}

.apply_user_imgurl {
  width: 100%;
  height: 100%;
  border-radius: 2rem;
}

.badge-icon {
  display: block;
  font-size: 10px;
  line-height: 16px;
}

.apply_userInfo_nickname {
  text-align: center;
  color: darkgray;
  line-height: 2rem;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0px;
  padding-top: 10px;
}

.phone {
  /* 叠加 */
  display: flex;
  flex-direction: row-reverse;
  /* 子级元素垂直居中 */
  align-items: center;
}
</style>