<template>
    <div>
        <div class="orderToEdit_back">
            <van-nav-bar title="订单修改">
                <template #left>
                    <van-button class="orderToEdit_backBut" @click="orderToEditBack" size="normal" :icon="left" type="default">
                        返回
                    </van-button>
                </template>
            </van-nav-bar>
        </div>
        <div class="orderToEdit_checkbox">
            <van-list>
                <van-checkbox-group v-model="checkboxChange">
                    <van-cell v-for="item,index in initData" :key="index">
                        <van-row>
                            <van-col span="12">
                                <van-checkbox :name="item.id" style="font-size: 12px;">
                                    {{item.code}}
                                    <br/>
                                    {{item.name}}
                                </van-checkbox>
                            </van-col>
                            <van-col span="6">
                                <van-field style="font-size: 14px;padding: 8px 6px;" v-model="item.quantity" placeholder="输入整数" type="digit"/>
                            </van-col>
                            <van-col span="6">
                                <van-field style="font-size: 14px;padding: 8px 6px;"
                                    readonly
                                    clickable
                                    name="calendar"
                                    :value="item.date"
                                    placeholder="选择日期"
                                    @click="showCalendarBox(item)"/>
                            </van-col>
                        </van-row>
                    </van-cell>
                </van-checkbox-group>
            </van-list>
        </div>
        <div style="margin: 10px 15px;">
            <van-button round block type="info" @click="submit">提交</van-button>
        </div>
        <van-calendar :show-confirm="false" v-model="showCalendar" @confirm="onConfirm" />
    </div>
</template>
<script>
import {orderMainItem,orderMainEdit} from '../api/index.js';
import{ Dialog,Toast } from 'vant';
export default {
    data() {
        return {
            left:require('../assets/left.png'),
            initData:[],
            checkboxChange:[],
            showCalendar: false,
            interimId:'',
        }
    },
    created() {
        let orderMainId=this.$route.params.orderMainId;
        orderMainItem({id:orderMainId}).then(res =>{
            this.initData=res.sqlData;
            this.checkboxChange=res.changeData;
        })
    },
    mounted() {
        
    },
    methods: {
        onConfirm(date){
            this.initData.forEach((e) =>{
                if(e.id===this.interimId){
                    e.date=`${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
                }
            })
            this.showCalendar = false;
        },
        showCalendarBox(item){
            this.interimId=item.id;
            this.showCalendar=true;
        },
        submit(){
            if(this.checkboxChange.length==0){
                Dialog.alert({
                    title: '系统提示',
                    message: '产品数量为空...!',
                }).then(() => {

                });
            }else{
                let insertData=[];
                let this_=this;
                let change=this.checkboxChange;
                for (let i = 0; i < change.length; i++){
                        let orderItem=this_.initData.find(item => item.id==change[i]);
                        if(orderItem.quantity=="" || orderItem.quantity==0){
                            Toast('请检查订货的数量是否正确..!');
                            return false;
                        }
                        insertData.push({"orderMain":orderItem.order_main,"pid":orderItem.pid,
                            "quantity":orderItem.quantity,"date":orderItem.date});
                }
                if(insertData.length>0){
                    let toast = Toast.loading({message: '数据提交中...!',forbidClick: true,duration:0,overlay:true});
                    orderMainEdit({"data":insertData}).then(res =>{
                        toast.clear();
                        if(res.result==1){
                            Dialog.alert({
                                title: '系统提示',
                                message: '保存成功!',
                            }).then(() => {
                                this.$router.push('/OrderInquiry');
                            });
                        }else{
                            Dialog.alert({
                                title: '系统提示',
                                message: '服务器连接失败，请稍后重试...!',
                            }).then(() => {
                            });
                        }
                    })
                }
            }
        },
        orderToEditBack(){
            this.$router.push('/OrderToModify');
        }
    },
}
</script>
<style scoped>
.orderToEdit_back{
    background-color: white;
}
.orderToEdit_backBut{
    padding-left: 0px;
    padding-right: 0px;
    border: none;
}
.orderToEdit_checkbox{
    margin-top: 5px;
    border: 1px solid rgb(238, 238, 238);
    height: 82vh;
    overflow:auto;
}
</style>