import axios from 'axios';
import {encrypt} from './aes_ecb';
//添加一个请求拦截器
let token = '';
axios.interceptors.request.use(function (config) {
    let userInfo = JSON.parse(window.localStorage.getItem("userInfo"));
    if (userInfo != null) {
        let openid = userInfo['openid'];
        let timestamp = Date.now();
        let tokenJson = {};
        tokenJson['openid'] = openid;
        tokenJson['timestamp'] = timestamp;
        token = encrypt(JSON.stringify(tokenJson));
    }
    config.headers.common['token'] = token;
    return config;
}, function (error) {
    console.info("error: ");
    console.info(error);
    return Promise.reject(error);
});
export const authorize = obj => axios.get('/ydgzh/weixin/authorize', obj).then(res => res.data);//跳转网页
export const wxLogin = obj => axios.post('/ydgzh/weixin/login', obj).then(res => res.data);//登录
export const getConfig = obj => axios.post('/ydgzh/weixin/getConfig', obj).then(res => res.data);//获取微信接口配置信息
export const sendLogin = obj => axios.post('/ydgzh/message/sendLogin', obj).then(res => res.data);//发送登录消息
export const updateUser = obj => axios.post('/ydgzh/user/update', obj).then(res => res.data);//个人信息更新
export const sendUserCheck = obj => axios.post('/ydgzh/message/sendUserCheck', obj).then(res => res.data);//发送客户审核消息
export const scanProduct = obj => axios.post('/ydgzh/product/scan', obj).then(res => res.data);//发货扫描
export const countProduct = obj => axios.get('/ydgzh/product/count', obj).then(res => res.data);//发货统计
export const listProduct = obj => axios.get('/ydgzh/product/list', obj).then(res => res.data);//发货列表(某个公司)
export const messageInfo = obj => axios.post('/ydgzh/message/getInfo', obj).then(res => res.data);//消息信息


export const listUserCheck = obj => axios.get('/ydgzh/user/listForCheck', obj).then(res => res.data);//待审核用户列表
export const checkUser = obj => axios.post('/ydgzh/user/check', obj).then(res => res.data);//审核用户
export const listUserAll = obj => axios.get('/ydgzh/user/list', obj).then(res => res.data);//待审核用户列表
export const getOneUser = obj => axios.get('/ydgzh/user/'+ obj).then(res => res.data);//待审核用户
export const countProductForUser = obj => axios.post('/ydgzh/manage/count', obj).then(res => res.data);//发货统计
export const listProductForUser = obj => axios.post('/ydgzh/manage/list', obj).then(res => res.data);//发货列表(某个客户)
export const downloadProduct = obj => axios.post('/ydgzh/manage/download', obj).then(res => res.data);//下载发货数据


export const clientEdit = obj => axios.post('/yd/client/edit', obj).then(res => res.data);//账号修改
export const addressAdd = obj => axios.post('/yd/address/add', obj).then(res => res.data);//添加收货地址
export const addressData = obj => axios.post('/yd/address/initData', obj).then(res => res.data);//初始化收货地址
export const addressFind = obj => axios.post('/yd/address/find', obj).then(res => res.data);//查询收货地址
export const addressDel = obj => axios.post('/yd/address/del', obj).then(res => res.data);//删除收货地址
export const applyAdd = obj => axios.post('/yd/apply/add', obj).then(res => res.data);//新增申请认证
export const productData = obj => axios.post('/yd/product/download', obj).then(res => res.data);//下载产品数据模板
export const myProduct = obj => axios.post('/yd/product/myProduct', obj).then(res => res.data);//下载产品数据模板
export const orderMainAdd = obj => axios.post('/yd/orderMain/add', obj).then(res => res.data);//新增订单
export const orderMainMyOrder = obj => axios.post('/yd/orderMain/myOrder', obj).then(res => res.data);//我的订单
export const orderMainItem = obj => axios.post('/yd/orderMain/orderItem', obj).then(res => res.data);//订单详情
export const orderMainAbandon = obj => axios.post('/yd/orderMain/orderAbandon', obj).then(res => res.data);//订单作废
export const orderMainRegain = obj => axios.post('/yd/orderMain/orderRegain', obj).then(res => res.data);//订单恢复
export const orderMainEdit = obj => axios.post('/yd/orderMain/orderEdit', obj).then(res => res.data);//订单修改
export const orderMainStatus = obj => axios.post('/yd/orderMain/statusOrder', obj).then(res => res.data);//订单修改
export const scanRecordReceiving = obj => axios.post('/yd/scanRecord/receiving', obj).then(res => res.data);//收货扫描
export const scanRecordShipment = obj => axios.post('/yd/scanRecord/shipment', obj).then(res => res.data);//发货扫描
export const inventoryInitData = obj => axios.post('/yd/inventory/initData', obj).then(res => res.data);//发货扫描


