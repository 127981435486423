<template>
  <div class="apply_body">
    <van-row>
      <div class="apply_left">
        <van-icon name="arrow-left" size="24" color="#928FFF" @click="applyBack" v-if="code !== '0'" />
        <van-icon name="cross" size="24" color="#928FFF" @click="onCancel" v-if="code === '0'" />
      </div>
    </van-row>
    <div class="apply_userInfo_imgDiv">
      <img :src="headimgurl" class="apply_user_imgurl"/>
      <van-badge color="#928FFF" v-if="this.flag === 1">
        <div class="child" />
        <template #content>
          <van-icon name="manager-o" class="badge-icon" />
        </template>
      </van-badge>
    </div>
    <div class="apply_userInfo_nickname">
      <span>{{ nickname }}</span>
    </div>
    <div class="apply_title">
      <span>基本信息</span>
    </div>
    <van-cell-group inset class="apply_userInfo" >
      <van-cell title="公司名称" :value="company" />
      <van-cell title="真实姓名" :value="name" />
      <van-cell title="联系电话" :value="phone" />
    </van-cell-group>
    <div class="apply_title">
      <span>资料审核</span>
    </div>
    <van-form class="apply_userInfo" @submit="onSubmit">
      <van-field name="switch" label="是否通过">
        <template #input>
          <van-switch v-model="stateBool" size="20px" active-color="#07C160" inactive-color="#EE0A24" @change="switchChange" />
        </template>
      </van-field>
      <van-field required
                 :rules="rules"
                 v-model.trim="reason"
                 type="textarea"
                 label="驳回原因"
                 placeholder="若审核不通过，请填写驳回原因。"
                 maxlength="50"
                 show-word-limit
                 v-if="this.stateBool === false"
      />
      <div style="margin: 16px;">
        <van-button round block color="#928FFF" native-type="submit">确认</van-button>
      </div>
    </van-form>
  </div>
</template>
<script>

import {checkUser, getOneUser} from "@/api";
import {Dialog} from "vant";
import {getUrlCode} from "@/api/common";

export default {
  name: "UserCheck",
  data() {
    return {
      userId: undefined,
      code: '',
      headimgurl: '',
      nickname: '',
      company: '',
      name: '',
      phone: '',
      state: undefined,
      flag: undefined,
      stateBool: false,
      reason: '',
      rules: [{ required: true, reason: '不能为空' }]
    }
  },
  created() {
    // history.pushState(null, null, document.URL);
    // window.addEventListener('popstate', function () {
    //   history.pushState(null, null, document.URL);
    // });
  },
  mounted() {
    this.userId = this.$route.params.userId;
    if (this.userId === undefined) {
      this.userId = getUrlCode().userId;
    }
    this.code = getUrlCode().code;

    let userInfo = JSON.parse(window.sessionStorage.getItem('userCheck'));
    if (userInfo === null) {
      getOneUser(this.userId).then(res => {
        userInfo = res.data;
        this.headimgurl = userInfo['headimgurl'];
        this.nickname = userInfo['nickname'];
        this.company = userInfo['company'];
        this.name = userInfo['name'];
        this.phone = userInfo['phone'];
        this.state = userInfo['state'];
        this.flag = userInfo['flag'];
        if (this.state === 0) {
          this.stateBool = true;
        } else {
          this.stateBool = false;
        }
        if (this.state < 2) {
          Dialog.alert({
            title: '系统提示',
            message: '该次审核已处理',
            confirmButtonColor: '#928FFF',
            closeOnPopstate: false
          }).then(() => {
            this.onCancel();
          }).catch(() => {
          });
        }
      });
    } else {
      this.headimgurl = userInfo['headimgurl'];
      this.nickname = userInfo['nickname'];
      this.company = userInfo['company'];
      this.name = userInfo['name'];
      this.phone = userInfo['phone'];
      this.state = userInfo['state'];
      this.flag = userInfo['flag'];
      if (this.state === 0) {
        this.stateBool = true;
      } else {
        this.stateBool = false;
      }
    }
  },
  methods: {
    applyBack() {
      window.history.back();
      // this.$router.push('/userCheckList');
    },
    onCancel() {
      setTimeout(function () {
        document.addEventListener('WeixinJSBridgeReady', function () {
          this.WeixinJSBridge.call('closeWindow') //安卓手机关闭代码
        },false)
        this.WeixinJSBridge.call('closeWindow') //苹果手机关闭代码
      }, 300)
    },
    onSubmit() {
      checkUser('userId=' + this.userId + '&stateBool=' + this.stateBool + '&reason=' + this.reason).then(res => {
        Dialog.alert({
          title: '系统提示',
          message: res.msg,
          confirmButtonColor: '#928FFF',
          closeOnPopstate: false
        }).then(() => {
          if (this.code === '0') {
            this.onCancel();
          } else {
            this.$router.push('/userCheckList');
          }
        });
      })
    },
    switchChange() {
      if (this.stateBool === false) {
        this.rules = [{ required: true, reason: '不能为空' }];
        this.reason = '';
      } else {
        this.rules = [];
        this.reason = ' ';
      }
    }
  },
}
</script>
<style scoped>
.apply_body {
  padding-top: 15px;
  margin-left: 10px;
  margin-right: 10px;
}

.apply_left {
  float: left;
}

.apply_title {
  margin-left: 15px;
  color: rgb(126, 126, 126);
  line-height: 2rem;
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 0px;
}

.apply_userInfo {
  margin: 0;
  padding: 10px;
  border: 1px solid rgb(224, 224, 224);
  background-color: white;
  border-radius: 15px;
}

.apply_userInfo_imgDiv {
  width: 4rem;
  height: 4rem;
  margin: auto;
}

.apply_user_imgurl {
  width: 100%;
  height: 100%;
  border-radius: 2rem;
}

.badge-icon {
  display: block;
  font-size: 10px;
  line-height: 16px;
}

.apply_userInfo_nickname {
  text-align: center;
  color: darkgray;
  line-height: 2rem;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0px;
  padding-top: 10px;
}
</style>