<template>
    <div>
        <div class="orderInquiry_back">
            <van-nav-bar title="订单查询">
                <template #left>
                    <van-button class="orderInquiry_backBut" @click="OrderInquiryBack" size="normal" :icon="left" type="default">
                        返回
                    </van-button>
                </template>
            </van-nav-bar>
        </div>
        <div v-if="initData.length>0" class="orderInquiry_data">
            <van-cell-group>
                <van-cell v-for="order in initData" :key="order.id" :title="order.createTime | dateFormat">
                    <template #right-icon>
                        <van-icon @click="orderInfo(order.id)" :name="xq" class="search-icon"/>
                    </template>
                </van-cell>
            </van-cell-group>
        </div>
        <div v-if="initData.length==0">
            <div class="orderInquiry_empty">
                没有订单数据....!
            </div>
        </div>
    </div>
</template>
<script>
import {orderMainMyOrder} from '../api/index.js';
import{ Toast } from 'vant';
export default {
    data() {
        return {
            left:require('../assets/left.png'),
            xq:require('../assets/xiangqing.png'),
            initData:[],
        }
    },
    created() {
        let toast = Toast.loading({message: '数据加载中...',forbidClick: true,duration:0,overlay:true});
        orderMainMyOrder().then(res =>{
            toast.clear();
            this.initData=res.sqlData;
        })
    },
    filters: {
        dateFormat: (carTime) => {
            let now = new Date(carTime)
            let y = now.getFullYear()
            let m = (now.getMonth() + 1).toString().padStart(2, '0')
            let d = now.getDate().toString().padStart(2, '0')
            return `${y}-${m}-${d}`
        }
    },
    mounted() {
        
    },
    methods: {
        orderInfo(id){
            this.$router.push({
                name: 'OrderDetails',
                params: {
                    orderMainId: id
                }
            })
        },
        OrderInquiryBack(){
            this.$router.push('/');
        },
    },
}
</script>
<style scoped>
.orderInquiry_back{
    background-color: white;
}
.orderInquiry_backBut{
    padding-left: 0px;
    padding-right: 0px;
    border: none;
}
.orderInquiry_data{
    margin-top: 5px;
    border: 1px solid rgb(238, 238, 238);
    height: 92vh;
    overflow:auto;
}
.orderInquiry_but{
    border: none;
}
.search-icon {
    font-size: 16px;
    line-height: inherit;
  }
.orderInquiry_empty{
    border: 1px solid rgb(240, 240, 240);
    background-color: white;
    border-radius: 10px;
    margin: 10px;
    line-height: 30vh;
    color: darkgray;
    text-align: center;
}
</style>