import Vue from 'vue'
import Index from './Index'
import router from './router/index';
import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);

Vue.config.productionTip = false

new Vue({
  render: h => h(Index),
  router,
}).$mount('#app')
/*window.addEventListener('popstate', function () {
  history.pushState(null, null, document.URL);
});*/
