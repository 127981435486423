<template>
    <div>
        <div>
            <van-nav-bar title="账号授权">
                <template #left>
                    <van-button class="apply_backBut" @click="applyBack" size="normal" :icon="left" type="default">
                        返回
                    </van-button>
                </template>
            </van-nav-bar>
        </div>
        <div class="apply_body">
            <div class="apply_f_title">
                授权账号
            </div>
            <div style="height:8px;"></div>
            <div class="apply_userInfo">
                <div style="height:8px;"></div>
                <van-row>
                    <van-col span="8">
                        <div class="apply_userInfo_imgDiv">
                            <img :src="headimgurl" class="apply_user_imgurl"/>
                        </div>
                    </van-col>
                    <van-col span="16">
                        <div class="apply_userInfo_nickname">
                            {{nickname}}
                        </div>
                    </van-col>
                </van-row>
                <div style="height:8px;"></div>
            </div>
            <div style="height:15px;"></div>
            <div class="apply_f_title">
                授权基本信息
            </div>
            <div style="height:8px;"></div>
            <div class="apply_company">
                <div style="height:5px;"></div>
                <van-form @submit="onSubmit">
                    <van-field required
                        v-model.trim="account.company"
                        label="公司名称"
                        placeholder="公司名称"
                    />
                    <van-field required
                        v-model.trim="account.name"
                        label="真实姓名"
                        placeholder="真实姓名"
                    />
                    <van-field required
                        v-model.trim="account.tel"
                        type="digit"
                        label="联系电话"
                        placeholder="联系电话"
                    />
                    <van-field required label="营业执照">
                        <template #input>
                            <van-uploader v-model.trim="imgData"
                              :max-count="1"
                              :after-read="afterRead"
                              :before-delete="deleteImg" />
                        </template>
                    </van-field>
                    <div style="margin: 16px;">
                        <van-button round block type="info" native-type="submit">申请认证</van-button>
                    </div>
                </van-form>
            </div>
        </div>
    </div>
</template>
<script>
import { applyAdd } from '../api/index.js';
import { Dialog } from 'vant';
import axios from "axios";
import {compressFile} from "../utils/compressFile";
export default {
    data() {
        return {
            left:require('../assets/left.png'), //返回
            headimgurl:'',
            nickname:'',
            account:{
                company:'',
                name:'',
                tel:'',
                credentialUrl:'',
            },
            imgData:[],
        }
    },
    created() {
        let user=JSON.parse(window.sessionStorage.getItem("userInfo"));
        this.headimgurl=user.headimgurl;
        this.nickname=user.nickname;
    },
    mounted() {
        
    },
    methods: {
        //删除照片事件
        deleteImg() {
            this.account.credentialUrl='';
            return true
        },
        //上传图片
        afterRead(file) {
            let _file = file
            _file.status = 'uploading';
            _file.message = '图片上传中...';
            let content = file.file;
            let _this = this
            compressFile(content, function (file) {
                let data = new FormData();
                data.append('file', file);
                let config = {
                    headers: { //添加请求头
                        Authorization:
                            "Bearer " + window.localStorage.getItem("managementToken"),
                        "Content-Type": "multipart/form-data"
                    }
                };
                let url = "/yd/uploadImage/addImages";
                axios.post(url, data, config).then(res => {
                    _this.account.credentialUrl=res.data.hUrl;
                    _file.status = "done"
                }).catch(err => {
                    console.log(err)
                });
            })
        },
        applyBack(){
            this.$router.push('/');
        },
        onSubmit(){
            if(this.account.company==='' || this.account.name==='' || this.account.tel==='' || this.credentialUrl===''){
                Dialog.alert({
                    title: '系统提示',
                    message: '请完善基本信息！',
                }).then(() => {
                });
                return false;
            }
            let user=JSON.parse(window.sessionStorage.getItem("userInfo"));
            user.company=this.account.company;
            user.name=this.account.name;
            user.tel=this.account.tel;
            user.credentialUrl=this.account.credentialUrl;
            applyAdd(user).then(res =>{
                if(res.result==1){
                    window.sessionStorage.setItem("ydGzhToken",res.ydGzhToken);
                }
                Dialog.alert({
                    title: '提示',
                    message: res.msg,
                }).then(() => {
                });
            })
        }
    },
}
</script>
<style scoped>
.apply_body{
    margin: 1rem;
}
.apply_f_title{
    color: rgb(126, 126, 126);
    font-size: 0.8rem;
}
.apply_userInfo{
    border: 1px solid rgb(224, 224, 224);
    background-color: rgb(245, 245, 245);
    border-radius: 5px;
}
.apply_company{
    border: 1px solid rgb(224, 224, 224);
    background-color:white;
    border-radius: 5px;
}
.apply_userInfo_imgDiv{
    width: 4rem;
    height: 4rem;
    margin: auto;
}
.apply_user_imgurl{
    width: 100%;
    height: 100%;
    border-radius: 2rem;
}
.apply_userInfo_nickname{
    color: darkgray;
    font-size: 1rem;
    line-height: 4rem;
}
.apply_backBut{
    padding-left: 0px;
    padding-right: 0px;
    border: none;
}
</style>