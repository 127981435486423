<template>
    <div>
        <div class="addAddress_back">
            <van-nav-bar title="收货地址编辑">
                <template #left>
                    <van-button class="addAddress_backBut" @click="addAddressBack" size="normal" :icon="left" type="default">
                        返回
                    </van-button>
                </template>
            </van-nav-bar>
        </div>
        <div style="height:10px;"></div>
        <van-address-edit
            :area-list="areaList"
            show-delete
            show-set-default
            show-postal
            :address-info="AddressInfo"
            :area-columns-placeholder="['请选择', '请选择', '请选择']"
            @save="onSave"
            @delete="onDelete"
            />
    </div>
</template>
<script>
import { addressAdd,addressFind,addressDel } from '../api/index.js';
import{ Toast,Dialog } from 'vant';
import { areaList } from '@vant/area-data';
export default {
    data() {
        return {
            left:require('../assets/left.png'), //返回
            AddressInfo :{},
            areaList:areaList,
        }
    },
    created() {
        let addressId=this.$route.params.addressId;
        if(addressId!=null){
            addressFind({"id":addressId}).then(res =>{
                this.AddressInfo=res.addressInfo;
            })
        }
    },
    mounted() {
        
    },
    methods: {
        onSave(e) {
            addressAdd(e).then(res =>{
                Dialog.alert({
                        title: '系统提示',
                        message: res.msg,
                }).then(() => {
                    if(res.result==1){
                        this.$router.push('/MyAddress');
                    }else{
                        return false;
                    }
                });
            })
        },
        onDelete() {
            addressDel({"id":this.AddressInfo.id}).then(res =>{
                if(res.result==1){
                    this.$router.push('/MyAddress');
                }else{
                    Toast(res.msg);
                }
            })
        },
        addAddressBack(){
            this.$router.push('/MyAddress');
        }
    },
}
</script>
<style scoped>
.addAddress_back{
    background-color: white;
}
.addAddress_backBut{
    padding-left: 0px;
    padding-right: 0px;
    border: none;
}
</style>