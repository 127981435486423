<template>
    <div>
        <div class="myAddress_back">
            <van-nav-bar title="我的收货地址">
                <template #left>
                    <van-button class="myAddress_backBut" @click="myAddressBack" size="normal" :icon="left" type="default">
                        返回
                    </van-button>
                </template>
            </van-nav-bar>
        </div>
        <div style="height:10px;"></div>
        <div v-if="addressList.length==0" class="myAddress_address0">
            <div style="height:15rem;"></div>
            还没有收货地址哦...！
        </div>
        <div>
            <van-address-list
            v-model="chosenAddressId"
            :list="addressList"
            add-button-text="新增收货地址"
            default-tag-text="默认"
            @add="onAdd"
            @edit="onEdit"/>
        </div>
    </div>
</template>
<script>
import { addressData } from '../api/index.js';
import { Toast } from 'vant';
export default {
    data() {
        return {
            left:require('../assets/left.png'), //返回
            chosenAddressId: '',
            addressList: [],
        }
    },
    created() {
        const toast=Toast.loading({duration:0,message: '加载中...！',forbidClick: true,});
        toast.open();
        addressData().then(res =>{
            if(res.result==1){
                this.chosenAddressId=res.chosenAddressId;
                this.addressList=res.addressData;
            }
            toast.clear();
        })
    },
    mounted() {
        
    },
    methods: {
        myAddressBack(){
            this.$router.push('/MyProfile');
        },
        onAdd() {
            this.$router.push('/AddAddress');
        },
        onEdit(item) {
            this.$router.push({
                name: 'AddAddress',
                params: {
                    addressId: item.id
                }
            })
        },
    },
}
</script>
<style scoped>
.myAddress_back{
    background-color: white;
}
.myAddress_backBut{
    padding-left: 0px;
    padding-right: 0px;
    border: none;
}
.myAddress_address0{
    text-align: center;
    color: darkgray;
    background-color: white;
    margin: 0.9rem;
    border: 1px solid rgb(245, 245, 245);
    border-radius: 0.3rem;
    height: 30rem;
} 
</style>