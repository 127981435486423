<template>
    <div>
        <div class="placeAnOrder_back">
            <van-nav-bar title="我要下单">
                <template #left>
                    <van-button class="placeAnOrder_backBut" @click="myPlaceAnOrderBack" size="normal" :icon="left" type="default">
                        返回
                    </van-button>
                </template>
            </van-nav-bar>
        </div>
        <div>
            <van-cell-group>
                <van-field v-model.trim="fileNmae" placeholder="请点击上传文件按键实现上传" disabled/>
            </van-cell-group>
            <div style="height:2px;"></div>
            <van-uploader accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel" :after-read="fileAfterRead">
                <van-button :icon="upload" style="margin-left:10px;" type="info" size="small">上传文件</van-button>
            </van-uploader>
            <van-button :icon="download" style="margin-left:10px;" type="info" size="small" @click="downloadExcel">模板下载</van-button>
            <van-button :icon="download" style="margin-left:10px;" type="info" size="small"  @click="downloadExcelData">数据模板下载</van-button>
        </div>
        <div class="placeAnOrder_checkbox" v-if="fileData.length==0">
            <van-list>
                <van-checkbox-group v-model="checkboxChange">
                    <van-cell v-for="item,index in checkboxData" :key="index">
                        <van-row>
                            <van-col span="12">
                                <van-checkbox :name="item.id" style="font-size: 16px;">
                                    {{item.code}}
                                    <br/>
                                    {{item.name}}
                                </van-checkbox>
                            </van-col>
                            <van-col span="6">
                                <van-field style="font-size: 14px;padding: 8px 6px;" v-model="item.quantity" placeholder="输入整数" type="digit"/>
                            </van-col>
                            <van-col span="6">
                                <van-field style="font-size: 14px;padding: 8px 6px;"
                                    readonly
                                    clickable
                                    name="calendar"
                                    :value="item.date"
                                    placeholder="选择日期"
                                    @click="showCalendar2Box(item)"/>
                            </van-col>
                        </van-row>
                    </van-cell>
                </van-checkbox-group>
            </van-list>
        </div>
        <div class="placeAnOrder_checkbox" v-if="fileData.length>0">
            <van-list>
                <van-checkbox-group v-model="fileDataChange">
                    <van-cell v-for="item,index in fileData" :key="index">
                        <van-row>
                                <van-col span="12">
                                    <van-checkbox :name="item.id" style="font-size: 12px;">
                                        {{item.code}}
                                        <br/>
                                        {{item.name}}
                                    </van-checkbox>
                                </van-col>
                                <van-col span="6">
                                    <van-field style="font-size: 14px;padding: 8px 6px;" v-model="item.quantity" placeholder="输入整数" type="digit"/>
                                </van-col>
                                <van-col span="6">
                                    <van-field
                                        readonly
                                        clickable
                                        name="calendar"
                                        :value="item.date"
                                        placeholder="选择日期"
                                        @click="showCalendarBox(item)"/>
                                </van-col>
                        </van-row>
                    </van-cell>
                </van-checkbox-group>
            </van-list>
        </div>
        <div style="padding: 16px;">
            <van-button round block type="info" @click="submit">提交</van-button>
        </div>
        <van-calendar :show-confirm="false" v-model="showCalendar" @confirm="onConfirm" />
        <van-calendar :show-confirm="false" v-model="showCalendar2" @confirm="onConfirm2" />
    </div>
</template>
<script>
import {productData,myProduct,orderMainAdd} from '../api/index.js';
import XLSX from "xlsx";
import{ Toast,Dialog } from 'vant';
export default {
    data() {
        return {
            left:require('../assets/left.png'),
            download:require('../assets/xiadan/download.png'),
            upload:require('../assets/xiadan/upload.png'),
            fileNmae:'',
            fileData:[],
            fileDataChange:[],
            checkboxData:[],
            checkboxChange:[],
            placeAnOrderShow:false,
            editEntity:{},
            showCalendar: false,
            showCalendar2: false,
            showCalendarId:'',
            showCalendarId2:'',
            
        }
    },
    created() {
        let toast = Toast.loading({message: '数据加载中...',forbidClick: true,duration:0,overlay:true});
        myProduct().then(res =>{
            this.checkboxData=res.sqlData
            toast.clear();
        })
    },
    mounted() {
    },
    methods: {
        submit(){
            if(this.fileData.length>0){
                //导入的数据保存
                let this_=this;
                if(this.fileDataChange.length>0){
                    let change=this.fileDataChange;
                    let insertData=[];
                    for (let i = 0; i < change.length; i++) {
                        let orderItem=this_.fileData.find(item => item.id==change[i]);
                        if(orderItem.quantity=="" || orderItem.quantity==0){
                            Toast('请检查订货的数量是否正确..!');
                            return false;
                        }
                        insertData.push({"id":orderItem.id,"quantity":orderItem.quantity,"date":orderItem.date});
                    }
                    if(insertData.length>0){
                        let toast = Toast.loading({message: '数据提交中...!',forbidClick: true,duration:0,overlay:true});
                        orderMainAdd({"data":insertData}).then(res =>{
                            toast.clear();
                            if(res.result==1){
                                Dialog.alert({
                                    title: '系统提示',
                                    message: '保存成功..!',
                                }).then(() => {
                                    this.$router.push('/OrderInquiry');
                                });
                            }else{
                                Dialog.alert({
                                    title: '系统提示',
                                    message: '保存失败，请保持‘系统编码’与模板一致..!',
                                }).then(() => {
                                });
                            }
                        })
                    }
                }else{
                    Toast('没有选中的产品..!');
                } 
            }else{
                //查询的数据保存
                if(this.checkboxChange.length>0){
                    let this_=this;
                    let insertData=[];
                    let change=this.checkboxChange;
                    for (let i = 0; i < change.length; i++){
                        let orderItem=this_.checkboxData.find(item => item.id==change[i]);
                        if(orderItem.quantity=="" || orderItem.quantity==0){
                            Toast('请检查订货的数量是否正确..!');
                            return false;
                        }
                        if(orderItem.date==""){
                            Toast('请检查到货的日期是否正确..!');
                            return false;
                        }
                        insertData.push({"id":orderItem.id,"quantity":orderItem.quantity,"date":orderItem.date});
                    }
                    if(insertData.length>0){
                        let toast = Toast.loading({message: '数据提交中...!',forbidClick: true,duration:0,overlay:true});
                        orderMainAdd({"data":insertData}).then(res =>{
                            toast.clear();
                            if(res.result==1){
                                Dialog.alert({
                                    title: '系统提示',
                                    message: '保存成功!',
                                }).then(() => {
                                    this.$router.push('/OrderInquiry');
                                });
                            }else{
                                Dialog.alert({
                                    title: '系统提示',
                                    message: '服务器连接失败，请稍后重试...!',
                                }).then(() => {
                                });
                            }
                        })
                    }
                }else{
                    Toast('没有选中的产品..!');
                }
            }
        },
        showCalendarBox(item){
            this.editEntity=item;
            this.showCalendar=true;
        },
        showCalendar2Box(item){
            this.showCalendarId=item.id;
            this.showCalendar2=true;
        },
        onConfirm(date) {
            this.fileData.forEach((e) =>{
                if(e.id===this.editEntity.id){
                    e.date=`${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
                }
            })
            this.showCalendar = false;
        },
        onConfirm2(date){
            this.checkboxData.forEach((e) =>{
                if(e.id===this.showCalendarId){
                    e.date=`${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
                }
            })
            this.showCalendar2 = false;
        },
        itemDelete(item){
            Dialog.confirm({
                title: '系统提示',
                message: '是否确定删除！',
            }).then(() => {
                this.fileData.splice(this.fileData.indexOf(item), 1);
            }).catch(() => {
            });
        },
        formatDate(numb, format) {
            const time = new Date((numb - 1) * 24 * 3600000 + 1)
            time.setYear(time.getFullYear() - 70)
            const year = time.getFullYear() + ''
            const month = time.getMonth() + 1 + ''
            const date = time.getDate() - 1 + ''
            if (format && format.length === 1) {
                return year + format + month + format + date
            }
            return year + (month < 10 ? '0' + month : month) + (date < 10 ? '0' + date : date)
        },
        fileAfterRead(e){
            let file=e.file;
            this.fileNmae=file.name
            let fileReader = new FileReader();
            fileReader.onload = ev => {
                try {
                    const data = ev.target.result;
                    const workbook = XLSX.read(data, {
                        type: "binary", codepage: 936 // 以字符编码的方式解析
                    });
                    const exlname = workbook.SheetNames[0]; // 取第一张表
                    let exl = XLSX.utils.sheet_to_json(workbook.Sheets[exlname]); // 生成json表格内容
                    let itemData={};
                    if(exl.length>0){
                        this.fileData=[];
                        this.fileDataChange=[];
                        for(let i=0;i<exl.length;i++){
                            if(i>0){
                                if(exl[i].__EMPTY_6!='' && exl[i].__EMPTY_7!='' && exl[i].__EMPTY_7.toString().trim()!=''){
                                    let date=this.formatDate(exl[i].__EMPTY_7,'/');
                                    if(new Date(date)<new Date()){
                                        this.fileData=[];
                                        Toast.fail('到货时间不能小于当前时间！');
                                        return false;
                                    }
                                    itemData={"id":exl[i].裕德订单,"type":exl[i].__EMPTY,"series":exl[i].__EMPTY_1,"code":exl[i].__EMPTY_2,
                                        "figureNumber":exl[i].__EMPTY_3,"name":exl[i].__EMPTY_4,"unit":exl[i].__EMPTY_5,
                                        "quantity":exl[i].__EMPTY_6,"date":date};
                                    this.fileData.push(itemData);
                                    this.fileDataChange.push(exl[i].裕德订单);
                                }
                            }
                        }
                    }
                } catch (e) {
                    console.log("出错了：：");
                return false;
                }
            };
            fileReader.readAsBinaryString(file);
        },
        myPlaceAnOrderBack(){
            this.$router.push('/');
        },
        formatJson(filterVal, jsonData) {
            return jsonData.map(v => filterVal.map(j => v[j]))
        },
        downloadExcelData(){
            productData().then(res =>{
                import("../excel/Export2Excel").then(excel => {
                    const title = ['裕德订单'];
                    const header = ['系统编码','产品类型', '产品系列', '产品编码', '产品图号','产品名称','单位','数量','期望到货日期(yyyy-MM-dd)'];
                    const filterVal = ['id', 'type', 'series', 'code', 'figureNumber','name','unit','quantity','date'];
                    const data = this.formatJson(filterVal,res.sqlData);
                    const multiHeader = [];
                    const merges = ["A1:I1"];
                    excel.export_json_to_excel({title,multiHeader,header,merges,data,filename: "裕德订单(数据)",
                        autoWidth: this.autoWidth,
                        bookType: this.bookType
                    });
                });
            })
        },
        downloadExcel(){
            import("../excel/Export2Excel").then(excel => {
                    const title = ['裕德订单'];
                    const header = ['系统编码','产品类型', '产品系列', '产品编码', '产品图号','产品名称','单位','数量','期望到货日期(yyyy-MM-dd)'];
                    const filterVal = ['id', 'type', 'series', 'code', 'figureNumber','name','unit','quantity','date'];
                    const data = this.formatJson(filterVal,[]);
                    const multiHeader = [];
                    const merges = ["A1:I1"];
                    excel.export_json_to_excel({title,multiHeader,header,merges,data,filename: "裕德订单",
                        autoWidth: this.autoWidth,
                        bookType: this.bookType
                    });
            });
        }
    },

}
</script>
<style scoped>
.placeAnOrder_back{
    background-color: white;
}
.placeAnOrder_backBut{
    padding-left: 0px;
    padding-right: 0px;
    border: none;
}
.placeAnOrder_checkbox{
    margin-top: 5px;
    border: 1px solid rgb(238, 238, 238);
    height: 70vh;
    overflow:auto;
}
</style>