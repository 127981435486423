<template>
    <div>
        <div>
            <van-nav-bar title="收货扫描">
                <template #left>
                    <van-button class="receivingScan_backBut" @click="receivingScanBack" size="normal" :icon="left" type="default">
                        返回
                    </van-button>
                </template>
            </van-nav-bar>
        </div>
        <div>
            <div style="height:3px;"></div>
            <van-button block :icon="scan" type="default" @click="richScan">
                扫一扫
            </van-button>
        </div>
        <div class="receivingScan_info">
            <div v-if="scanData.length==0" class="receivingScan_empty">
                没有扫描数据...!
            </div>
            <div v-if="scanData.length>0">
                <van-list>
                    <van-cell style="font-size: 12px;" v-for="(item,index) in scanData" :key="index">
                        {{item}}
                    </van-cell>
                </van-list>
            </div>
        </div>
    </div>
</template>
<script>
import {getScanConfig,scanRecordReceiving} from '../api/index.js';
import {Toast} from 'vant';
import wx from 'weixin-js-sdk';
export default {
    data() {
        return {
            left:require('../assets/left.png'),
            scan:require('../assets/scan.png'),
            scanConfig:{},
            scanData:[],
        }
    },
    created() {
        if(!Object.prototype.hasOwnProperty.call(this.scanConfig, 'jsapi_ticket')){
            let toast = Toast.loading({message: '配置文件加载中...',forbidClick: true,duration:0,overlay:true});
            getScanConfig({url:window.location.href}).then(res =>{
                this.scanConfig=res;
                toast.clear();
            })
        }
    },
    mounted() {
        
    },
    methods: {
        richScan(){
            let this_=this;
            wx.config({
                debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                appId: this.scanConfig.appId, // 必填，公众号的唯一标识
                timestamp: this.scanConfig.timestamp, // 必填，生成签名的时间戳
                nonceStr: this.scanConfig.nonceStr, // 必填，生成签名的随机串
                signature: this.scanConfig.signature,// 必填，签名
                jsApiList: ['scanQRCode'] // 必填，需要使用的JS接口列表
            });
            wx.ready(function(){
                wx.scanQRCode({
                    needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                    scanType: ['qrCode', 'barCode'], // 可以指定扫二维码还是一维码，默认二者都有
                    success: function(res2) {
                        let result = res2.resultStr; // 当needResult 为 1 时，扫码返回的结果
                        let item=this_.scanData.find(item => item==result);
                        if(typeof(item)=="undefined"){
                            scanRecordReceiving({"scanCode":result}).then((res) =>{
                                if(res.rint==1){
                                    this_.scanData.push(result+" "+res.product);
                                }
                                Toast(res.msg);
                            })
                        }else{
                            Toast("请不要多次扫描同一件产品...!");
                        }
                    }
                })
          });
          wx.error(function(res){
            Toast(res);
          });
        },
        receivingScanBack(){
            this.$router.push('/');
        }
    },
}
</script>
<style scoped>
.receivingScan_backBut{
    padding-left: 0px;
    padding-right: 0px;
    border: none;
}
.receivingScan_info{
    margin-top: 5px;
    border: 1px solid rgb(238, 238, 238);
    height: 85vh;
    overflow:auto;
}
.receivingScan_empty{
    border: 1px solid rgb(240, 240, 240);
    background-color: white;
    border-radius: 10px;
    margin: 10px;
    line-height: 30vh;
    color: darkgray;
    text-align: center;
}
</style>