<template>
    <div>
        <div class="orderToModify_back">
            <van-nav-bar title="订单修改">
                <template #left>
                    <van-button class="orderToModify_backBut" @click="OrderToModifyBack" size="normal" :icon="left" type="default">
                        返回
                    </van-button>
                </template>
            </van-nav-bar>
        </div>
        <div v-if="initData.length>0" class="orderToModify_data">
            <van-list>
                <van-cell v-for="(item,index) in initData" :key="index">
                    <van-row>
                        <van-col span="16" class="orderToModify_item">
                            {{item.createTime | dateFormat}}
                        </van-col>
                        <van-col span="8" style="text-align: center;">
                            <span v-if="item.isDeal==1" style="color: darkgray;">已发货</span>
                            <van-button v-if="item.state==1 && item.isDeal==0" @click="abandon(item.id)" class="orderToModify_but" round type="danger" size="mini">作废</van-button>
                            <van-button v-if="item.state==0 && item.isDeal==0" @click="regain(item.id)" class="orderToModify_but" round type="primary" size="mini">恢复</van-button>
                            <van-button v-if="item.state==1 && item.isDeal==0" @click="edit(item.id)" class="orderToModify_but" round type="info" size="mini">修改</van-button>
                        </van-col>
                    </van-row>
                </van-cell>
            </van-list>
        </div>
        <div v-if="initData.length==0">
            <div class="orderToModify_empty">
                没有订单数据....!
            </div>
        </div>
    </div>
</template>
<script>
import {orderMainMyOrder,orderMainAbandon,orderMainRegain,orderMainStatus} from '../api/index.js';
import{ Toast,Dialog } from 'vant';
export default {
    data() {
        return {
            left:require('../assets/left.png'),
            initData:[],
        }
    },
    created() {
        let toast = Toast.loading({message: '数据加载中...',forbidClick: true,duration:0,overlay:true});
        orderMainMyOrder().then(res =>{
            toast.clear();
            this.initData=res.sqlData;
        })
    },
    filters: {
        dateFormat: (carTime) => {
            let now = new Date(carTime)
            let y = now.getFullYear()
            let m = (now.getMonth() + 1).toString().padStart(2, '0')
            let d = now.getDate().toString().padStart(2, '0')
            return `${y}-${m}-${d}`
        }
    },
    mounted() {
        
    },
    methods: {
        //作废
        abandon(id){
            Dialog.confirm({
                title: '系统提示',
                message: '作废后将无法对订单进行修改!',
            }).then(() => {
                orderMainAbandon({mainId:id}).then(res =>{
                    if(res.rint==1){
                        Toast.success(res.msg);
                    }else{
                        Toast.fail(res.msg);
                    }
                    orderMainMyOrder().then(res =>{
                        this.initData=res.sqlData;
                    })
                })
            }).catch(() => {
            });
        },
        //恢复
        regain(id){
             Dialog.confirm({
                title: '系统提示',
                message: '是否确定恢复!',
            })
            .then(() => {
                orderMainRegain({mainId:id}).then(res =>{
                    if(res.result==1){
                        this.initData.find(item => item.id==id).state=1;
                    }else{
                        console.log(res.msg);
                    }
                })
            })
            .catch(() =>{
            })
        },
        //修改
        edit(id){
            orderMainStatus({"id":id}).then((res) =>{
                if(res.rint==1){
                    Toast.fail(res.msg);
                    orderMainMyOrder().then(res =>{
                        this.initData=res.sqlData;
                    })
                }else{
                    this.$router.push({
                        name: 'OrderToEdit',
                        params: {
                            orderMainId: id
                        }
                    })
                }
            })
            
        },
        OrderToModifyBack(){
            this.$router.push('/');
        }
    },
}
</script>
<style scoped>
.orderToModify_back{
    background-color: white;
}
.orderToModify_backBut{
    padding-left: 0px;
    padding-right: 0px;
    border: none;
}
.orderToModify_data{
    margin-top: 5px;
    border: 1px solid rgb(238, 238, 238);
    height: 92vh;
    overflow:auto;
}
.orderToModify_empty{
    border: 1px solid rgb(240, 240, 240);
    background-color: white;
    border-radius: 10px;
    margin: 10px;
    line-height: 30vh;
    color: darkgray;
    text-align: center;
}
.orderToModify_but{
    margin-left: 5px;
}
.orderToModify_item{
    font-size: 12px;
}
</style>