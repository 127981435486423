<template>
  <div class="apply_body">
    <div class="apply_top">
      <div class="apply_left">
        <van-icon name="arrow-left" size="24" color="#928FFF" @click="applyBack" />
      </div>
      <van-search clearable placeholder="请输入客户姓名 / 公司名称"
                  v-model="inputValue" @input="handleSearch(false)" />
<!--      <div class="apply_right">
        <van-icon name="filter-o" size="24" color="#928FFF" />
      </div>-->
    </div>
    <div class="apply_info">
      <van-empty description="暂无客户" v-if="users.length === 0" />
      <!--    <van-cell-group class="apply_info" v-if="users.length !== 0" >
            <van-cell v-for="item in usersSearch"
                      :key="item['id']"
                      :title="item['name']"
                      :label="item['company']"
                      :value="item['phone']"
                      @click="handleSkip(item['id'])"
            />
          </van-cell-group>-->

      <van-index-bar :index-list="indexList" v-if="users.length !== 0">
        <div v-for="(item,index) in peopleList" :key="index">
          <van-index-anchor :index="item.index" />
          <van-cell-group>
            <van-cell v-for="user in item.list"
                      :key="user['id']"
                      :title="user['name']"
                      :label="user['company']"
                      :value="user['phone']"
                      @click="handleSkip(user['id'])"
            />
          </van-cell-group>
        </div>
      </van-index-bar>
    </div>

  </div>
</template>

<script>
import {listUserAll} from "@/api";
import {parseTime} from "@/api/common";
import pinyin from "js-pinyin"

export default {
  name: "UserList",
  data() {
    return {
      code: '',
      users: [],
      usersSearch: [],
      inputValue: '',
      letterList: ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z"],
      indexList: [],
      peopleList: []
    }
  },
  created() {
    // history.pushState(null, null, document.URL);
    // window.addEventListener('popstate', function () {
    //   history.pushState(null, null, document.URL);
    // });
  },
  mounted() {
    listUserAll().then(res => {
      this.users = res.data;
      this.usersSearch = this.users;

      this.handleFirstName();
    });
  },
  methods: {
    applyBack() {
      this.$router.push('/manageIndex');
    },
    parseTimeStr(time, pattern) {
      return parseTime(time, pattern);
    },
    handleSearch() {
      let searchResult = this.users.filter((sr) => {
        return sr.name.indexOf(this.inputValue) !== -1;
      });
      if (searchResult.length === 0) {
        searchResult = this.users.filter((sr) => {
          return sr.company.indexOf(this.inputValue) !== -1;
        });
      }
      this.usersSearch = searchResult;
      this.handleFirstName();
    },
    handleFirstName() {
      this.indexList = [];
      this.peopleList = [];
      this.letterList.forEach((index) => {
        let people = {};
        people['list'] = [];
        this.usersSearch.forEach((user) => {
          let name = user['name'];
          let first = pinyin.getFullChars(name).substring(0, 1);
          if (first === index) {
            people['list'].push(user);
          }
        });
        if (people['list'].length !== 0) {
          this.indexList.push(index);
          people['index'] = index;
          this.peopleList.push(people);
        }
      });
    },
    handleSkip(id) {
      this.users.forEach((item) => {
        if (item['id'] === id) {
          window.sessionStorage.setItem('user', JSON.stringify(item));
        }
      });
      /* 跳转 */
      this.$router.push({
        name: 'UserInfo',
        params: {
          userId: id
        }
      });
      // let url = location.href.split('#')[0] + '#/userInfo?userId=' + id
      // window.location.assign(url);
    }
  },
}
</script>

<style scoped>
.apply_body {
  margin-left: 10px;
  margin-right: 10px;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.apply_top {
  background-color: rgb(248,248,248);
  padding-top: 15px;
  padding-bottom: 15px;
}

.apply_left {
  padding-right: 10px;
  float: left;
}

.apply_right {
  padding-left: 10px;
  float: right;
}

.van-search {
  padding: 0;
  height: 24px;
}

.van-search__content {
  background-color: white;
}

.apply_info {
  flex: 1;
  overflow-y: scroll;
}

.van-cell__title {
  min-width: 50%;
}

.van-cell__label {
  width: 250px;
}

.van-cell__value {
  min-width: 50%;
}

/deep/ .van-index-anchor--sticky {
  color: #928FFF;
}

/deep/ .van-index-bar__index--active {
  color: #928FFF;
}

</style>